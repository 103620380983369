import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tr-unsupported',
  templateUrl: './unsupported.component.html',
  styleUrls: ['./unsupported.component.scss']
})
export class UnsupportedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
