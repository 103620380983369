import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MessengerRoutingModule } from './messenger-routing.module';
import { ContainerComponent } from './container/container.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatListModule} from '@angular/material/list';
import {ChatModule} from '../chat/chat.module';
import { ChatPreviewComponent } from './chat-preview/chat-preview.component';
import { ChatPanelComponent } from './chat-panel/chat-panel.component';
import {SharedModule} from '../shared/shared.module';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
  declarations: [ContainerComponent, ChatPreviewComponent, ChatPanelComponent],
  exports: [
    ContainerComponent
  ],
  imports: [
    CommonModule,
    MessengerRoutingModule,
    MatProgressSpinnerModule,
    MatListModule,
    ChatModule,
    SharedModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class MessengerModule { }
