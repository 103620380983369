<ngx-masonry [options]="options" class="w-100" [ordered]="ordered" (itemsLoaded)="itemsLoaded.next(true)" #masonry>
  <div ngxMasonryItem
       *ngFor="let i of images;index as idx; trackBy: imageTrackBy" [style.width]="'calc((100% / '+(responsiveColumns$ | async)+') - '+gutter+'px)'"
       class="image-item"
       [prepend]="i.prepend"
       [style.margin-bottom.px]="gutter"
       (click)="openViewer(viewer, idx)">
    <img [src]="i.url" width="100%">
  </div>
</ngx-masonry>

<tr-viewer [images]="images" #viewer [class.d-none]="!(viewerOpen | async)"></tr-viewer>

