import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'tr-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {

  public converter = [
    {lang: 'it', flag: 'it.png'},
    {lang: 'en', flag: 'en.png'},
  ];

  public index = 0;

  constructor(private t: TranslateService) {

  }


  public nextLang() {
    this.index ++;
    if (this.index >= this.converter.length) {
      this.index = 0;
    }
    this.t.use(this.converter[this.index].lang);
  }

  ngOnInit(): void {
    this.t.onLangChange.pipe(
      tap(
        (event) => {
          this.index = this.converter.findIndex(x => x.lang === event.lang);
        }
      )
    ).subscribe();
  }

}
