import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {GlobalsService} from '../globals.service';

@Component({
  selector: 'tr-connection-issues',
  templateUrl: './connection-issues.component.html',
  styleUrls: ['./connection-issues.component.scss']
})
export class ConnectionIssuesComponent implements OnInit {

  actions = [
    {icon: 'refresh', label: 'Reload page', action: () => window.location.reload(), name: 'reload'},
    {icon: 'add_to_home_screen', label: 'Use your Smartphone', action: () => this.showQrCode = !this.showQrCode, name: 'mobile'},
    {icon: 'cloud_download', label: 'Get Google Chrome', action: () => window.open('https://www.google.com/chrome/', '_blank'), name: 'chrome'},
  ];

  clientUrl = environment.clientUrl;
  showQrCode = false;

  constructor(public g: GlobalsService) { }

  ngOnInit(): void {
  }

  reload() {
    window.location.reload();
  }
}
