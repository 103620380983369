import {Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable, of, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, concatMap, filter, map, tap} from 'rxjs/operators';
import {GlobalsService} from './globals.service';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {User} from './classes/models/User';
import {marker as trans} from '@biesbjerg/ngx-translate-extract-marker';
import {PusherService} from './pusher.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {PeopleExtendedItem} from './classes/models/PeopleExtendedItem';
import {debug} from './classes/system/ObservableDebug';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private g: GlobalsService, private router: Router, private pusher: PusherService, private ga: GoogleAnalyticsService) {

    this.g.logout$.pipe(
      tap(() => this.logout())
    ).subscribe();

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(this.g.isBrowser ? this.storage.getItem('currentUser') : null));
    this.currentUser = this.currentUserSubject.asObservable();
    setTimeout(() => {
      this.currentUser.pipe(
        filter((user) => user != null),
        debug('New User'),
        map((user) => {
          if (
            (this.g.c.language.useUserLanguageForStreaming || this.g.c.language.useUserLanguageForPlatform) &&
            user.lang == null
          ) {
            this.logout();
            return null;
          }
          return user;
        }),
        filter((user) => user != null),
        tap((user) => this.ga.set({user_id: user.id})),
        // tap((user) => Sentry.setUser({email: user.email, id: user.id.toString()})),
        tap((user) => this.setupNotifications(user)),
        tap((user) => this.g.log$.next({entity: 'login', id: user.id})),
        concatMap(() => this.g.postLogin())
      ).subscribe();
    }, 0);
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public setupNotifications(user: User) {
    if (user && this.g.c.switches.usePusher) {
      this.pusher.init(user.token, user.id);
      this.g.setupStatusHandler();
    }
  }

  toggleHideProfile(value) {
    return this.http.post<any>(`${environment.BASE_URL}/user-profile/hide`, {hide: value})
  }

  get storage() {
    return this.g.c.switches.useSessionStorage ? sessionStorage : localStorage;
  }

  login(data) {
    return this.http.post<any>(`${environment.BASE_URL}/auth/login`, data)
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            if (
              (this.g.c.language.useUserLanguageForStreaming || this.g.c.language.useUserLanguageForPlatform) &&
              user.lang == null
            ) {
              throw new Error('No language specified');
            }
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (this.g.isBrowser) {
              this.storage.setItem('currentUser', JSON.stringify(user));
            }

            this.currentUserSubject.next(user);
          }
          return user;
        }),
        catchError((error) => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            switch (error.status) {
              case 404:
                this.router.navigate(['/auth/register']);
                this.g.loaded();
                return null;
              case 400:
                this.g.loaded();
                return throwError(trans('auth.service.invalidRequest'));
              case 401:
                this.g.loaded();
                return throwError(error.error);
              case 500:
              default:
                this.g.loaded();
                this.router.navigate(
                  ['/error'],
                  {queryParams: {m: 'auth.service.genericIssue'}}
                );
                break;
            }
          }
          return throwError(
            trans('auth.service.generalError'));
        })
      );
  }

  logout() {
    if (!this.g.isBrowser) {
      return;
    }
    this.g.log$.next({entity: 'logout', id: this.currentUserValue?.id});
    // remove user from local storage to log user out
    this.storage.removeItem('currentUser');
    // window.location.href = environment.clientUrl + '/auth/login';
    this.router.navigate(['/auth/login']);
    this.currentUserSubject.next(null);
  }

  register(data) {
    return this.http.post<any>(`${environment.BASE_URL}/auth/user`, data);
  }

  passwordReset(email) {
    return this.http.post<any>(`${environment.BASE_URL}/auth/user/password-reset`, {email});
  }

  newPassword(h, t, newPassword) {
    return this.http.post<any>(`${environment.BASE_URL}/auth/user/new-password`, {h, t, newPassword});
  }

  resendActivation(email) {
    return this.http.post<any>(`${environment.BASE_URL}/auth/user/resend-activation-email`, {email});
  }

  getProfileData() {
    return this.http.get<PeopleExtendedItem>(`${environment.BASE_URL}/user-profile`).pipe(
      map((userItem) => {
        if (userItem && userItem.dateOfBirth) {
          userItem.dateOfBirth = moment(userItem.dateOfBirth);
        }
        return userItem;
      })
    );
  }

  updateUserProfile(data) {
    return this.http.put<PeopleExtendedItem>(`${environment.BASE_URL}/user-profile`, data);
  }

  public restErrorHandler(error, caught) {
    this.logout();
    return of(null);
  }
}
