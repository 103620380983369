import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NotificationBase} from '../../shared/classes/system/NotificationBase';
import {NotificationAspectBase} from '../_classes/NotificationAspectBase';

@Component({
  selector: 'tr-call-lost',
  templateUrl: './call-lost.component.html',
  styleUrls: ['./call-lost.component.scss']
})
export class CallLostComponent extends NotificationAspectBase implements OnInit {

  @Input() data: NotificationBase;
  @Output() actionClicked = new EventEmitter<boolean>();

  ngOnInit(): void {

  }


}
