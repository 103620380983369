import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {IChat} from '../../shared/classes/chat/ichat';
import {ChatService} from '../../shared/chat.service';
import {AuthenticationService} from '../../shared/authentication.service';
import {Subscription} from 'rxjs';
import {concatMap, tap} from 'rxjs/operators';
import {GlobalsService} from '../../shared/globals.service';

@Component({
  selector: 'tr-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  chats: IChat[] = [];
  message: string;
  sending: boolean;
  loader = true;

  @ViewChild('messageBox') messageBox: ElementRef;

  @Input() showInput = true;

  // tslint:disable-next-line:variable-name
  private _channelName: string;
  get channelName(): string {
    return this._channelName;
  }

  @Input()
  set channelName(val: string) {
    this.disconnect();
    this._channelName = val;
    this.connect();
  }

  private sub: Subscription;
  private scrollContainer: any;

  @ViewChild('chatlog', {static: false}) chatLog: ElementRef;
  private isNearBottom: boolean;
  public scrollPos: number;

  private channel: any;

  @Input() classes: string;
  @Output() messageReceived = new EventEmitter<boolean>();

  constructor(private chatService: ChatService, public authService: AuthenticationService, private g: GlobalsService) {
  }

  ngOnInit() {
    // subscribe to pusher's event
    // this.connect();

  }

  removeMessage(chat) {
    this.chats = this.chats.filter(x => x.id !== chat.id);
  }

  private disconnect() {
    if (this.channel) {
      this.chatService.unsubscribe(this.channelName);
      this.channel = null;
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  private connect() {
    this.loader = true;
    this.chats = [];

    // this.disconnect();

    if (!this.channelName) {
      return;
    }

    this.channel = this.chatService.subscribe(this.channelName);

    this.sub = this.chatService.join({}, this.channelName).pipe(
      concatMap((res) => this.chatService.getHistory(this.channelName)),
      tap((res: IChat[]) => {
        res.forEach(x => x.isMe = (+x.userId === this.authService.currentUserValue.id));
        this.chats.push(...res);
        this.channel.bind('chat', data => {
          if (data.type === 'joined') {
            return;
          }
          if (+data.userId === this.authService.currentUserValue.id) {
            data.isMe = true;
          } else {
            if (data.type !== 'joined') {
              this.messageReceived.emit(true);
            }
          }
          this.chats.push(data);
          if (this.isNearBottom) {
            this.scrollToBottom();
          }
        });
        this.channel.bind('chat-delete', data => {
          this.chats = this.chats.filter(x => Number(x.id) !== Number(data.id));
        });
        this.scrollToBottom();
        setTimeout(() => this.messageBox?.nativeElement.focus(), 50);
        this.loader = false;
      }),
    ).subscribe(
      () => {
      },
      (error) => {
        console.error(error);
        this.loader = false;
      });
  }

  sendMessage(message: string) {
    if (!message || message.length === 0) {
      return;
    }
    this.sending = true;
    this.chatService.sendMessage(message, this.channelName)
      .subscribe(resp => {
        this.message = undefined;
        this.sending = false;
        this.chatService.myMessage$.next({channel: this.channelName, message});
        setTimeout(() => this.messageBox?.nativeElement.focus(), 50);
      }, err => {
        this.sending = false;
        setTimeout(() => this.messageBox?.nativeElement.focus(), 50);
      });
  }

  ngOnDestroy(): void {
    this.disconnect();
  }


  public scrollToBottom(): void {
    setTimeout(() => {
      this.scrollContainer = this.chatLog?.nativeElement;
      this.scrollPos = this.scrollContainer?.scrollHeight;
    }, 50);
  }

  private isUserNearBottom(): boolean {
    const threshold = 150;
    const position = this.scrollContainer.scrollTop + this.scrollContainer.offsetHeight;
    const height = this.scrollContainer.scrollHeight;
    return position > height - threshold;
  }

  scrolled(event: any): void {
    this.isNearBottom = this.isUserNearBottom();
  }

}
