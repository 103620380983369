import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {FriendNotification} from '../../shared/classes/system/FriendNotification';
import {TrophyNotification} from '../../shared/classes/system/TrophyNotification';

@Component({
  selector: 'tr-notification-trophy-snack',
  templateUrl: './notification-trophy-snack.component.html',
  styleUrls: ['./notification-trophy-snack.component.scss']
})
export class NotificationTrophySnackComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: TrophyNotification,
              private snackBarRef: MatSnackBarRef<NotificationTrophySnackComponent>
  ) {
  }

  dismiss() {
    this.snackBarRef.dismiss();
  }

}
