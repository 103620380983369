import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {AgendaItem} from '../classes/models/EventData';
import {TimetableAgenda} from '../classes/models/Timetable';
import {Router} from '@angular/router';

@Component({
  selector: 'tr-agenda-item',
  templateUrl: './agenda-item.component.html',
  styleUrls: ['./agenda-item.component.scss']
})
export class AgendaItemComponent implements OnInit {

  @Input() agendaItem: TimetableAgenda;
  @Input() mode = 0;

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (this.agendaItem.dateFrom) {
      this.agendaItem.dateFrom = moment(this.agendaItem.dateFrom);
    }
  }

  openEvent() {
    this.router.navigate(['/event/' + this.agendaItem.id]);
  }

}
