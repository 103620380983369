import {Injectable} from '@angular/core';
import {PusherService} from './pusher.service';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {IChat} from './classes/chat/ichat';
import {Chat} from '../messenger/_classes/Chat';
import {UserChatStatus} from './classes/models/UserChatStatus';
import {NotificationCounters} from './classes/models/NotificationCounters';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  // user: { displayName: string, email: string };

  private endPoint = environment.BASE_URL + '/chat';
  private channels: any;

  public myMessage$ = new Subject<{channel: string, message: string}>();

  constructor(private pusherService: PusherService, private http: HttpClient) {
    this.channels = [];
  }

  subscribe(channelName) {
    const channel = this.pusherService.getPusher().subscribe(channelName);
    this.channels.push({name: channelName, channel});
    return channel;
  }

  unsubscribe(channelName) {
    this.pusherService.getPusher().unsubscribe(channelName);
    this.channels = this.channels.filter(x => x.name !== channelName);
  }

  create(destUserId): Observable<any> {
    return this.http.post(`${this.endPoint}`, {destUserId});
  }

  getList(): Observable<Chat[]> {
    return this.http.get<Chat[]>(`${this.endPoint}/list`).pipe(
      map((chatList) => {
        chatList.forEach((c) => c.lastMessageFromId = Number(c.lastMessageFromId));
        return chatList;
      })
    );
  }

  deleteMessage(id): Observable<boolean> {
    return this.http.post<any>(`${this.endPoint}/delete/${id}`, {});
  }

  join(param, channelName): Observable<any> {
    return this.http.post(`${this.endPoint}/join/${channelName}`, param)
      .pipe(tap(data => {
        // this.user = param;
      }));
  }

  sendMessage(message: string, channelName: string): Observable<any> {
    const param = {
      message,
      type: 'human',
      // ...this.user
    };
    return this.http.post(`${this.endPoint}/message/${channelName}`, param);
  }

  getChannel(channelName) {
    return this.channels.find(x => x.name = channelName);
  }

  getHistory(channelName: string): Observable<IChat[]> {
    return this.http.get<IChat[]>(`${this.endPoint}/history/${channelName}`);
  }

  getUserStatus(): Observable<UserChatStatus[]> {
    return this.http.get<UserChatStatus[]>(`${this.endPoint}/user-status`);
  }

  setUserStatus(status: UserChatStatus[]): Observable<UserChatStatus[]> {
    return this.http.post<UserChatStatus[]>(`${this.endPoint}/user-status`, status);
  }

  getCounters(): Observable<NotificationCounters> {
    return this.http.get<NotificationCounters>(`${environment.BASE_URL}/notifications/counters`);
  }
}
