import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PageNotFoundComponent} from './shared/page-not-found/page-not-found.component';
import {AuthGuard} from './shared/classes/auth/auth.guard';
import {ErrorPageComponent} from './shared/error-page/error-page.component';
import {UnsupportedComponent} from './shared/unsupported/unsupported.component';
import {AdminGuard} from './shared/classes/auth/admin.guard';
import {ConfigGuard} from './shared/classes/auth/config.guard';
import {umans} from './shared/globals.service';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
    canLoad: [AdminGuard],
    data: {
      requiredRoles: ['admin', 'staff']
    }
  },
  {
    path: 'keynote',
    loadChildren: () => import('./keynote/keynote.module').then(m => m.KeynoteModule),
    canActivate: [AuthGuard],
    data: {
      isKeynote: true,
      requiredRoles: []
    }
  },
  {
    path: 'meet',
    loadChildren: () => import('./meet/meet.module').then(m => m.MeetModule),
    canActivate: [AuthGuard],
    canLoad: [ConfigGuard],
    data: {
      isActive: umans()?.features?.meet,
      requiredRoles: []
    }
  },
  {
    path: 'polling',
    loadChildren: () => import('./polling/polling.module').then(m => m.PollingModule),
    canLoad: [AdminGuard],
    canActivate: [AuthGuard],
    data: {
      requiredRoles: ['admin']
    }
  },
  {
    path: 'questions',
    loadChildren: () => import('./questions-manager/questions-manager.module').then(m => m.QuestionsManagerModule),
    canActivate: [AuthGuard],
    data: {
      requiredRoles: [],
      requiredPerks: ['qmod']
    }
  },
  {path: 'error', component: ErrorPageComponent},
  {path: 'unsupported', component: UnsupportedComponent},
  {
    path: '',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuard],
    data: {
      requiredRoles: []
    }
  },
  // {path: '', pathMatch: 'full', redirectTo: 'home'},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
