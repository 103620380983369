import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {GlobalsService} from '../globals.service';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'tr-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent implements OnInit, OnDestroy {

  imagePath: string;

  get image(): string {
    return this.imagePath;
  }

  @Input() set image(value: string) {
    if (value !== null && value.length > 0) {
      if (value.match(/https?:\/\//g)) {
        this.imagePath = value;
      } else {
        this.imagePath = environment.assetsBucketUrl + '/images/users/' + value;
      }
    } else {
      this.imagePath = this.g.c.layout.speakerPlaceholder;
    }
  }

  @Input() status = '';
  @Input() imageSize: 's' | 'm' | 'l';
  @Input() cssClass;

  @Output() loaded$ = new BehaviorSubject<boolean>(false);

  public startLoading: boolean;

  private timeout;

  constructor(public g: GlobalsService, private cdf: ChangeDetectorRef) {
    this.startLoading = false;
  }

  ngOnInit(): void {
    if (!this.startLoading) {
      this.timeout = setTimeout(() => {
        this.startLoading = true;
        this.cdf.detectChanges();
      }, 0);
    }
  }

  ngOnDestroy(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }


}
