import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IChat} from '../../shared/classes/chat/ichat';
import {GlobalsService} from '../../shared/globals.service';
import {SubscriptionHandler} from '../../shared/classes/system/SubscriptionHandler';
import {from, of, Subject} from 'rxjs';
import {catchError, concatMap, filter, switchMap, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {ChatService} from '../../shared/chat.service';
import {marker as trans} from '@biesbjerg/ngx-translate-extract-marker';
import {AuthenticationService} from '../../shared/authentication.service';

@Component({
  selector: 'tr-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit, OnDestroy {

  @Input() chat: IChat;
  @Output() chatDeleted = new EventEmitter<boolean>();

  deleteChat$ = new Subject<boolean>();

  private sub = new SubscriptionHandler();

  showMenuButton: boolean;
  menuOpen: boolean;

  constructor(private g: GlobalsService, private ts: TranslateService,
              private chatService: ChatService,
              public auth: AuthenticationService) {
    this.sub.list = this.deleteChat$.pipe(
      switchMap(() => this.g.confirm(this.ts.instant(trans('chat.chat-message.deleteTitle')), this.ts.instant(trans('chat.chat-message.deleteMessage')))),
      filter((result) => result === true),
      concatMap(() => this.chatService.deleteMessage(this.chat.id)),
      catchError((err) => of(null)),
      filter((result) => result),
      tap(() => this.chatDeleted.emit(true))
    ).subscribe();
  }

  ngOnInit(): void {
  }

  toggleMenuButton(visible) {
    if (!visible && this.menuOpen) {
      return;
    }
    this.showMenuButton = visible && this.g.c.switches.allowChatMessageDelete;
  }

  menuOpened() {
    this.menuOpen = true;
  }

  menuClosed() {
    this.menuOpen = false;
    this.showMenuButton = false;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribeAll();
  }
}
