<div class="filter-bar d-flex justify-content-between align-items-center">
  <div class="d-flex align-items-sm-center flex-column flex-sm-row w-100">
    <div class="d-flex align-items-center justify-content-start w-100">
      <span class="title mr-sm-5 mr-2 pl-2 pl-sm-0">{{title}}</span>
      <mat-icon color="primary" class="d-none d-sm-inline-block">tune</mat-icon>
      <div class="filter mx-2" *ngFor="let filter of filters">
        <button mat-flat-button [matMenuTriggerFor]="afterMenu" [id]="filterButtonId"
                [color]="isActive(filter) ? 'accent' : 'primary'">{{filter.name}}</button>
<!--        <button mat-flat-button [matMenuTriggerFor]="afterMenu"-->
<!--                [color]="isActive(filter) ? 'accent' : 'primary'">Legenda</button>-->
        <mat-menu #afterMenu="matMenu" xPosition="after">
          <div class="w-100 d-flex align-items-stretch flex-column" (click)="$event.stopPropagation()">
            <button mat-menu-item *ngFor="let option of filter.filters" (click)="applyFilter(option)">
              <mat-icon class="selected-icon">{{option.selected ? 'check_circle' : ''}}</mat-icon>
              <span>{{option.label}}</span>
            </button>
          </div>
        </mat-menu>
      </div>
      <div class="mt-0" *ngIf="!(g.isHandset$ | async)">
        <ng-content></ng-content>
      </div>
      <mat-icon class="d-block d-sm-none mr-2 ml-auto" (click)="toggleSearchPanel()">search</mat-icon>
    </div>

    <mat-form-field appearance="outline" class="search-field d-none d-sm-block">
      <mat-label>{{searchBoxPlaceholder}}</mat-label>
      <input matInput type="text" [formControl]="searchBox">
      <span matPrefix><mat-icon>search</mat-icon></span>
      <button mat-button *ngIf="searchBox.value" matSuffix mat-icon-button [attr.aria-label]="'shared.filter-bar.clear' | translate"
              (click)="searchBox.reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

<!--    <div class="mt-2" *ngIf="(g.isHandset$ | async)">-->
<!--      <ng-content></ng-content>-->
<!--    </div>-->
  </div>

</div>
<mat-accordion class="w-100 d-block d-sm-none search-panel-filter-bar">
  <mat-expansion-panel [expanded]="panelOpenState" class="d-flex justify-content-center">
    <mat-form-field appearance="outline" class="search-field mobile pb-0 px-2 mw-100" style="width: 100vw">
      <mat-label>Search</mat-label>
      <input matInput type="text" [formControl]="searchBox">
      <span matPrefix><mat-icon>search</mat-icon></span>
      <button mat-button *ngIf="searchBox.value" matSuffix mat-icon-button [attr.aria-label]="'shared.filter-bar.clear' | translate"
              (click)="searchBox.reset()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </mat-expansion-panel>
</mat-accordion>
