import {Inject, Injectable} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {IncomingCallBase} from './classes/system/IncomingCallBase';
import {debug} from './classes/system/ObservableDebug';
import {concatMap, filter, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {PeopleExtendedItem} from './classes/models/PeopleExtendedItem';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {CALLING_TOKEN, INCOMING_CALL_TOKEN} from './classes/system/DialogTokens';
import {ComponentType} from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class CallService {

  public incomingCall$ = new Subject<IncomingCallBase>();
  public callAccepted$ = new Subject<boolean>();
  public callRejected$ = new Subject<boolean>();
  public callLost$ = new Subject<IncomingCallBase>();

  private endpoint = `${environment.BASE_URL}/people/call`;

  constructor(private http: HttpClient,
              private bottomSheet: MatBottomSheet,
              @Inject(INCOMING_CALL_TOKEN) private incomingCallComponent: ComponentType<any>,
              @Inject(CALLING_TOKEN) private callingComponent: ComponentType<any>,
              ) {
    this.incomingCall$.pipe(
      debug('Incoming Call'),
      tap((call) => this.showIncomingCall(call))
    ).subscribe();
  }

  call(user: PeopleExtendedItem): Observable<any> {
    return this.http.post(`${this.endpoint}/${user.id}`, {}).pipe(
      debug('Calling'),
      concatMap(() => {
        return this.showCalling({
          userId: user.id,
          title: user.firstName + ' ' + user.lastName,
          commonToken: user.commonToken,
          image: user.imageUrl
        });
      })
    );
  }

  acceptCall(userId) {
    return this.http.post(`${this.endpoint}/${userId}/accept`, {});
  }

  rejectCall(userId) {
    return this.http.post(`${this.endpoint}/${userId}/deny`, {});
  }

  reportLostCall(userId, reason) {
    return this.http.post(`${this.endpoint}/${userId}/lost-or-canceled`, {reason});
  }

  public showIncomingCall(incomingCall: IncomingCallBase) {
    const ref = this.bottomSheet.open(this.incomingCallComponent, {
      data: incomingCall
    });

    const sub = this.callLost$.pipe(
      debug('Call Lost'),
      tap(() => {
        ref.dismiss();
        sub.unsubscribe();
      })
    ).subscribe();
  }

  public showCalling(call: IncomingCallBase): Observable<any> {
    const ref = this.bottomSheet.open(this.callingComponent, {
      data: call
    });

    return ref.afterDismissed().pipe(
      debug('After Dismissed'),
      concatMap((data) => {
        if( data === 'closed') {
          return this.reportLostCall(call.userId, 'canceled');
        } else {
          return of(null);
        }
      })
    );
  }
}
