<div class="w-100 my-2 media-component mw-100 " [class.media-component]="showBackground">
  <ng-container *ngIf="item.mediaType === 50">
    <vg-player>
      <video #media [vgMedia]="media" id="singleVideo" preload="auto" controls (play)="g.log$.next({entity: 'media-download', id: item.id})">
        <source [src]="item.url" [type]="item.mimeType">
      </video>
    </vg-player>
  </ng-container>

  <div class="d-flex align-items-center " [class]="mode === 'v' ? 'flex-column justify-content-center' : 'justify-content-between'">
    <div class="icon-container d-flex justify-content-center align-items-center">
      <ng-container [ngSwitch]="item.mediaType">
        <mat-icon class="icon" *ngSwitchCase="50">theaters</mat-icon>
        <mat-icon fontSet="material-icons-outlined" style="color: #C90C0F" class="icon" *ngSwitchDefault>insert_drive_file</mat-icon>
      </ng-container>
    </div>
    <div class="d-flex flex-column mw-100 w-100 overflow-hidden">
      <div class="pl-2 mw-100 overflow-hidden" [class]="mode === 'v' ? 'w-50' : 'w-100'">
        <p class="text-ellipsis m-0 p-0" [matTooltip]="item.title"><strong>{{item.title}}</strong></p>
      </div>
      <div class="pl-2 "><p class="m-0 p-0 mat-caption"><em>{{item.fileSize | filesize}}</em></p></div>
    </div>
    <div class="action-container" *ngIf="item.mediaType !== 50">
      <a [href]="item.url" [download]="item.fileName"  [title]="item.title" target="_blank"
         (click)="g.log$.next({entity: 'media-download', id: item.id})">
        <mat-icon color="primary">get_app</mat-icon>
      </a>
    </div>
  </div>




</div>
