import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import {LayoutModule} from '@angular/cdk/layout';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {HttpLoaderFactory, SharedModule} from './shared/shared.module';
import {JwtInterceptor} from './shared/classes/auth/jwt.interceptor';
import {ErrorInterceptor} from './shared/classes/auth/error.interceptor';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en-US-POSIX';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {NotificationItemsModule} from './notification-items/notification-items.module';
import {MatButtonModule} from '@angular/material/button';
import {NgxGoogleAnalyticsModule} from 'ngx-google-analytics';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import {environment} from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MessengerModule} from './messenger/messenger.module';

registerLocaleData(localeIt);
registerLocaleData(localeEn, 'en');

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.clientUrl
  },
  palette: {
    popup: {
      background: '#1553A0',
      text: '#fff'
    },
    button: {
      background: '#C64073',
      text: '#fff'
    }
  },
  position: 'top',
  content: {
    message: 'Questa piattaforma web utilizza cookie, anche di terze parti. Oltre ai cookie tecnici per garantire all’utente la corretta navigazione sulla piattaforma, utilizziamo i cookies di Google Analytics per ottenere statistiche anonime sull’utilizzo della piattaforma da parte degli utenti.',
    dismiss: 'ACCETTO',
  },
  showLink: false,
  theme: 'edgeless',
  type: 'info'
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    NgxGoogleAnalyticsModule.forRoot(environment.GoogleAnalytics),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatMomentDateModule,
    LayoutModule,
    MatProgressSpinnerModule,
    SharedModule.forRoot(),
    MatMenuModule,
    MatIconModule,
    NotificationItemsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    MatButtonModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    MatToolbarModule,
    MessengerModule,
  ],
  providers: [
    HttpClient,
    // {provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'it'},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: false}},
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {
    //   },
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
