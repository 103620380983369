import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'tr-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  errorMessage: string;

  constructor(private route: ActivatedRoute, private ts: TranslateService) {
    this.route.queryParams.subscribe(params => {
      const msg = this.ts.instant(params.m);
      if (msg !== params.m) {
        this.errorMessage = msg;
      }
    });
  }

  ngOnInit(): void {
  }

}
