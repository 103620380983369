import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../../authentication.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {GlobalsService} from '../../globals.service';

const jwt = new JwtHelperService();

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private g: GlobalsService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;

    const requiredRoles: string[] = route.data.requiredRoles;
    const requiredPerks: string[] = route.data.requiredPerks;

    let roleAccess = false;
    let perkAccess = false;

    if (currentUser) {
      // if(currentUser.firstLogin) {
      //   this.router.navigate(['/auth/landing']);
      //   return false;
      // }
      if (!jwt.isTokenExpired(currentUser.token)) {

        if (!route.data.isKeynote && this.g.c.app.keynoteMode && currentUser.role !== 'admin') {
          this.router.navigate([this.g.c.app.keynoteUrl]);
        }

        roleAccess = (!requiredRoles || requiredRoles.length === 0 || requiredRoles.indexOf(currentUser.role) >= 0);
        perkAccess = (!requiredPerks || requiredPerks.length === 0 || requiredPerks.every(p => this.authenticationService.currentUserValue.perks.includes(p)));

        return roleAccess && perkAccess;
      }
    }
    // not logged in so redirect to login page with the return url
    return this.login(state);
  }

  login(state) {
    this.router.navigate(['/auth/login'], {queryParams: {returnUrl: this.g.c.app.keynoteMode ? this.g.c.app.keynoteUrl : state.url}});
    return false;
  }
}
