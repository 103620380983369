<div class="viewbox" (click)="closeClicked.next()">
  <ng-container *ngFor="let i of images; let idx = index; trackBy: imageTrackBy">
    <div class="img-container" (click)="closeClicked.next()">
      <img (click)="$event.stopPropagation()"
           *ngIf="selectedIndex$.value === idx"
           [src]="i.url" class="image"
           @carouselAnimation
      />
    </div>

  </ng-container>

  <div class="navigation left d-flex align-items-center">
    <button mat-mini-fab aria-label="Previous" color="primary" (click)="previous$.next($event)">
      <mat-icon>chevron_left</mat-icon>
    </button>
  </div>

  <div class="navigation right d-flex align-items-center">
    <button mat-mini-fab aria-label="Next" color="primary" (click)="next$.next($event)">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>

  <button mat-icon-button aria-label="Close" class="close-button text-white" (click)="closeClicked.next()">
    <mat-icon>close</mat-icon>
  </button>

</div>
