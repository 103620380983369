<div class="w-100 d-flex flex-column agenda-item-container py-2 pr-2 pl-3 my-2 cursor-pointer"
     [class.grey]="mode === 1 ? 1 : 0"
     [class.active]="agendaItem.isActive"
     [class.expired]="agendaItem.expired"
     (click)="openEvent()"
>
  <div class="d-flex justify-content-between">
    <span class="details">
      <span class="mat-body" *ngIf="agendaItem.dateFrom">
        <strong>{{agendaItem.dateFrom | localizedDate:'longDate'}}, {{agendaItem.dateFrom | localizedDate:'shortTime'}}</strong>
      </span><br>
      <span><em>{{agendaItem.roomName}}</em></span>
    </span>
    <span *ngIf="agendaItem.isActive" class="text-accent live"><strong>• {{'shared.agenda-item.live' | translate}}</strong></span>
  </div>
  <mat-divider class="my-1"></mat-divider>
  <h3 class="mat-subheading-2 mb-1"><strong>{{agendaItem.eventData.title | uppercase}}</strong></h3>
</div>
