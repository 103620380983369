<div class="d-flex flex-column align-items-center p-2">
  <h2 class="my-3">{{'shared.incoming-call.incoming' | translate}}</h2>
  <div class="d-flex flex-column align-items-center" [class.pulsate-fwd]="profileImage.loaded$ | async">
    <div class="call-avatar" >
      <tr-profile-image #profileImage [image]="data.image" class="image"></tr-profile-image>
    </div>
  </div>
  <div class="my-3">
    <p class="m-0 p-0"><strong>{{data.title}}</strong></p>
  </div>

  <div class="d-flex mt-4">
    <button mat-fab color="success" class="mx-3" (click)="accept$.next(true)">
      <mat-icon class="">call</mat-icon>
    </button>
    <button mat-fab color="error" class="mx-3" (click)="deny$.next(true)">
      <mat-icon class="">call_end</mat-icon>
    </button>
  </div>
</div>
