import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'tr-notification-snack-base',
  templateUrl: './notification-snack-base.component.html',
  styleUrls: ['./notification-snack-base.component.scss']
})
export class NotificationSnackBaseComponent {

  @Output() closed = new EventEmitter<boolean>();

  constructor() {
  }

  dismiss() {
    this.closed.emit(true);
  }

}
