// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // BASE_URL: 'https://localhost:8000',
  BASE_URL: 'https://8iq1mqhui0.execute-api.eu-south-1.amazonaws.com/prod',
  assetsBucketUrl: 'https://d25dut6g3cr33x.cloudfront.net',
  // BASE_URL: 'https://api-event.umans.com',
  // BASE_URL: 'https://api.umans.beta',
  socketUrl: 'https://ws-umans.therocks.it',
  clientUrl: 'https://localhost:4200',
  GoogleAnalytics: 'G-HCF4XVN9JZ',
  enableDebug: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
