import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat/chat.component';
import {FormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {SharedModule} from '../shared/shared.module';
import {MatMenuModule} from '@angular/material/menu';
import { ChatMessageComponent } from './chat-message/chat-message.component';



@NgModule({
  declarations: [ChatComponent, ChatMessageComponent],
  exports: [
    ChatComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        SharedModule,
        MatMenuModule
    ]
})
export class ChatModule { }
