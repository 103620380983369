import {Injectable} from '@angular/core';
import Pusher from 'pusher-js';
import {environment} from '../../environments/environment';
import {GlobalsService} from './globals.service';
import {NotificationsService} from './notifications.service';

@Injectable({
  providedIn: 'root'
})
export class PusherService {

  private pusher: any;

  constructor(private ns: NotificationsService, private g: GlobalsService) {

  }

  init(token, uid) {
    try {
      this.pusher = new Pusher(this.g.c.app.pusherKey, {
        cluster: this.g.c.app.pusherCluster,
        authEndpoint: `${environment.BASE_URL}/notifications/auth`,
        auth: {
          headers: {
            Authorization: 'JWT ' + token
          }
        }
      });

      this.pusher.bind('pusher:error', (error) => {
        if (error.code <= 4099 || error.code >= 4300) {
          this.g.connectionIssues$.next(true);
        }
      });

      this.ns.setupHandlers(this.pusher, uid);
    } catch (e) {
      this.g.connectionIssues$.next(true);
    }

  }

  getPusher() {
    return this.pusher;
  }
}
