<!--<h6 class="pb-2 mb-0">Group Chat <button class="btn btn-sm btn-primary">invite others</button></h6>-->
<div class="w-100 h-100 d-flex justify-content-center align-items-center" *ngIf="loader">
  <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
</div>
<div class="chats h-100 d-flex flex-column" *ngIf="!loader" [ngClass]="classes">
  <div class="chatbox flex-grow-1 py-2" #chatlog (scroll)="scrolled($event)" [scrollTop]="scrollPos">
    <tr-chat-message *ngFor="let chat of chats" [chat]="chat" (chatDeleted)="removeMessage(chat)"></tr-chat-message>
<!--    <div *ngFor="let chat of chats">-->
<!--      <div class="message sent mat-body" *ngIf="chat.type!=='joined' && chat.isMe">-->
<!--        {{chat.message}}-->
<!--        <span class="metadata">-->
<!--          <span class="time mat-caption">{{chat.createdAt | localizedDate: 'shortTime'}}</span>-->
<!--        </span>-->
<!--        <button mat-icon-button *ngIf="showMenuButton"-->
<!--                class="menu-button"-->
<!--                [matMenuTriggerFor]="messageMenu"-->
<!--                (menuOpened)="menuOpened(messageMenu)"-->
<!--                (menuClosed)="menuClosed(messageMenu)">-->
<!--          <mat-icon>expand_more</mat-icon>-->
<!--        </button>-->
<!--      </div>-->
<!--      <div *ngIf="chat.type!=='joined' && !chat.isMe" class="message received mat-body" [class.staff]="chat.type === 'staff'">-->
<!--        <strong>{{chat.displayName}}</strong> <br>-->
<!--        {{chat.message}}-->
<!--        <span class="metadata">-->
<!--          <span class="time mat-caption">{{chat.createdAt | localizedDate: 'shortTime'}}</span>-->
<!--        </span>-->
<!--        <button mat-icon-button *ngIf="showMenuButton && authService.currentUserValue.role !== 'attendee'"-->
<!--                class="menu-button"-->
<!--                [matMenuTriggerFor]="messageMenu"-->
<!--                (menuOpened)="menuOpened(messageMenu)"-->
<!--                (menuClosed)="menuClosed(messageMenu)">-->
<!--          <mat-icon>expand_more</mat-icon>-->
<!--        </button>-->
<!--      </div>-->

      <!--      <p class="joined w-100 text-center my-1"  *ngIf="chat.type==='joined'">-->
      <!--        <span class="mat-caption">{{chat.displayName}} joined</span>-->
      <!--      </p>-->
<!--    </div>-->
  </div>

  <mat-divider class="my-1 w-100"></mat-divider>
  <div class="d-flex p-2 align-items-center send-box" *ngIf="showInput">
    <input matInput
           #messageBox
           [(ngModel)]="message"
           type="text"
           class="form-control form-control-sm mr-2"
           placeholder="{{'chat.chat.enterMessage' | translate}}"
           autofocus
           [disabled]="sending"
           (keyup.enter)="sendMessage(message)">
    <button class="mx-2" mat-icon-button color="primary" [attr.aria-label]="'chat.chat.send' | translate"
            type="submit"
            [disabled]="!message || sending"
            (click)="sendMessage(message)">
      <mat-icon>{{sending ? 'more_horiz' : 'send'}}</mat-icon>
    </button>
  </div>
</div>
