<h1 mat-dialog-title>{{data.title | translate}}</h1>
<div mat-dialog-content>
  <div class="d-flex w-100 align-items-center">
    <div class="large-icon m-2">
      <mat-icon class="large-icon">warning</mat-icon>
    </div>
    <p>
      {{data.message | translate}}
    </p>
  </div>

</div>
<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="true" color="primary">{{'common.ok' | translate}}</button>
</div>
