import {Injectable} from '@angular/core';
import {CanLoad, Route, Router, UrlSegment, UrlTree} from '@angular/router';
import {AuthenticationService} from '../../authentication.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable} from 'rxjs';

const jwt = new JwtHelperService();

@Injectable({providedIn: 'root'})
export class AdminGuard implements CanLoad {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = this.authenticationService.currentUserValue;

    if (currentUser) {
      if (!jwt.isTokenExpired(currentUser.token)) {
        return currentUser.role === 'admin' || currentUser.role === 'staff';
      }
    }
    return false;
  }
}
