import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {NotificationBase} from '../../shared/classes/system/NotificationBase';

@Component({
  selector: 'tr-notification-snack',
  templateUrl: './notification-snack.component.html',
  styleUrls: ['./notification-snack.component.scss']
})
export class NotificationSnackComponent {


  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: NotificationBase, private snackBarRef: MatSnackBarRef<NotificationSnackComponent>) {
  }

  dismiss() {
    this.snackBarRef.dismiss();
  }


}

