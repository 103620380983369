<div class="d-flex h-100 w-100 align-items-center mw-100 mat-body p-2 noselect cursor-pointer chat-preview"
     [class.chat-selected]="selected" >
  <div class="profile-image-container mr-3">
    <tr-profile-image [image]="item.imageUrl" [status]="g.getStatusClass(status)" [imageSize]="'s'"></tr-profile-image>
  </div>
  <div class="d-flex flex-column mw-100 w-100 overflow-hidden">
    <p class="m-0 p-0"><strong>{{item.title}}</strong></p>
    <p class="m-0 p-0 mw-100 text-ellipsis" *ngIf="item.lastMessage" [class.text-bold]="!item.read">
      {{item.lastMessageFrom}}: {{item.lastMessage}}
    </p>
  </div>
</div>
<mat-divider></mat-divider>
