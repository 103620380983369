import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {Moment} from 'moment-timezone';
import {GlobalsService} from '../globals.service';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'tr-timed-content',
  templateUrl: './timed-content.component.html',
  styleUrls: ['./timed-content.component.scss']
})
export class TimedContentComponent implements OnInit {

  @Input() before: Moment;
  @Input() after: Moment;
  @Input() widthCustom: string;

  @HostBinding('style.height') componentHeight = '0';
  @HostBinding('style.width') componentWidth = '0';

  showContent$: Observable<boolean>;

  constructor(private g: GlobalsService) {
  }

  ngOnInit(): void {
    this.showContent$ = this.g.ticker$.pipe(
      map((now) => {
        if (this.before) {
          return now.isBefore(this.before);
        }
        if (this.after) {
          return now.isAfter(this.after);
        }
        return false;
      }),
      tap((show) => {
        if (show) {
          this.componentHeight = '100%';
          this.componentWidth = this.widthCustom;
        } else {
          this.componentHeight = '0';
          this.componentWidth = '0';
        }
      })
    );
  }


}
