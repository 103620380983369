import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NotificationBase} from '../../shared/classes/system/NotificationBase';
import {NotificationAspectBase} from '../_classes/NotificationAspectBase';

@Component({
  selector: 'tr-notification-base-element',
  templateUrl: './notification-base-element.component.html',
  styleUrls: ['./notification-base-element.component.scss']
})
export class NotificationBaseElementComponent extends NotificationAspectBase implements OnInit {

  @Input() data: NotificationBase;
  @Output() actionClicked = new EventEmitter<boolean>();

  isExternalUrl: boolean;

  ngOnInit(): void {
    this.isExternalUrl = !!this.data.action.match(/https?:\/\//g);
  }

}
