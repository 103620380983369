import {Injectable} from '@angular/core';
import {MediaType} from './classes/enums/MediaType';
import {AppConfig} from './classes/config/AppConfig';

@Injectable({
  providedIn: 'root'
})
export class ClientConfigService {

  public config = {
    mediaTypes: [
      {id: MediaType.File, icon: 'insert_drive_file'},
      {id: MediaType.Video, icon: 'theaters'}
    ]
  };

  constructor() {
  }

  getConfig(): AppConfig {
    return {
      ...this.config,
      ...JSON.parse(sessionStorage.getItem('app-config') ?? null)
    };
  }
}
