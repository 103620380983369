import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, combineLatest, forkJoin, merge, Observable, of} from 'rxjs';
import {Chat} from '../_classes/Chat';
import {ChatService} from '../../shared/chat.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {GlobalsService} from '../../shared/globals.service';
import {concatMap, filter, map, shareReplay, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {NotificationsService} from '../../shared/notifications.service';
import {UserChatStatus} from '../../shared/classes/models/UserChatStatus';
import {ChatInitialState, ChatState} from '../_classes/ChatState';
import {debug} from '../../shared/classes/system/ObservableDebug';
import {MatSidenav} from '@angular/material/sidenav';
import {AuthenticationService} from '../../shared/authentication.service';
import {SubscriptionHandler} from '../../shared/classes/system/SubscriptionHandler';

@Component({
  selector: 'tr-messenger-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit, OnDestroy {

  chats$: Observable<Chat[]>;
  selectedChannel: string;
  sub = new SubscriptionHandler();
  statuses: number[];
  @Input() floating = false;
  mobileVersion$: Observable<boolean>;

  public openChat$ = new BehaviorSubject<Chat>(null);

  @ViewChild('drawer') chatList: MatSidenav;

  public channelSelected$ = new BehaviorSubject<string>(null);

  constructor(private cs: ChatService,
              private route: ActivatedRoute,
              private router: Router,
              private auth: AuthenticationService,
              public g: GlobalsService,
              private ns: NotificationsService,
  ) {

    this.mobileVersion$ = this.g.isHandset$.pipe(
      map((isHandset) => isHandset || this.floating)
    );

    if (this.route.firstChild && !this.floating) {
      this.sub.list = this.route.firstChild.paramMap.subscribe(
        (params: ParamMap) => {
          this.channelSelected$.next(params.get('channel'));
        }
      );
    }
  }

  private initChatList$: Observable<ChatInitialState> = forkJoin(
    {
      userStatus: this.cs.getUserStatus(),
      chatList: this.cs.getList()
    }).pipe(
    debug('Init Chat List'),
    map((res) => {
      res.chatList.forEach(c => {
        const us = res.userStatus.find(s => s.channel === c.channel);
        if (!us) {
          res.userStatus.push({
            channel: c.channel,
            lastReadMessage: c.lastMessageId
          });
          c.read = false;
        } else {
          c.read = c.lastMessageId <= us.lastReadMessage;
        }

        if (c.lastMessageFromId === this.auth.currentUserValue.id) {
          c.lastMessageFrom = 'Me';
        }
      });
      return res;
    }),
    concatMap((res) => this.cs.setUserStatus(res.userStatus).pipe(
      debug('Status Set'),
      map((userStatus) => {
        return {chatList: res.chatList, userStatus};
      })
    ))
  );

  private selectChannel$ = combineLatest(
    [this.initChatList$, this.channelSelected$]
  ).pipe(
    debug('Selecting Channel'),
    concatMap(([state, selectedChannel]) => {
      if (selectedChannel && (selectedChannel !== this.selectedChannel)) {
        return this.selectChat(state, selectedChannel);
      }
      return of({state, selectedChannel});
    })
  );


  ngOnInit(): void {
    this.g.showChatNotifications = false;
    this.sub.list =
      this.openChat$.pipe(
        filter((chat) => chat !== null),
        debug('Open Chat Clicked'),
        switchMap((chat) => this.mobileVersion$.pipe(
          map((isHandset) => ({isHandset, chat}))
        )),
        tap((result) => {
          this.channelSelected$.next(result.chat.channel);
          if (result.isHandset) {
            this.chatList.close();
          }
          if (!this.floating) {
            this.router.navigate(['/chat/' + result.chat.channel]);
          }
        })
      ).subscribe();

    const chatList$ = this.initChatList$.pipe(
      switchMap((initialState) => {
        return combineLatest([this.selectChannel$, this.ns.chatNotifications]).pipe(
          debug('Updating Chat Object'),
          map(([chatState, newMessage]) => {
            if (newMessage) {
              const chat = chatState.state.chatList.find(x => x.channel === newMessage.channel);
              if (chat) {
                if (chat.lastMessageId !== newMessage.messageId) {
                  if (this.selectedChannel && chat.channel !== this.selectedChannel) {
                    chat.read = false;
                    this.ns.modifyCounters('add', 'chat', 1);
                  }
                  chat.lastMessage = newMessage.description;
                  chat.lastMessageFrom = newMessage.title;
                  chat.lastMessageId = newMessage.messageId;
                  this.setUserReadChat(chatState.state, chat);
                }
              } else {
                chatState.state.chatList.push({
                  channel: newMessage.channel,
                  read: false,
                  title: newMessage.title,
                  imageUrl: newMessage.image,
                  lastMessage: newMessage.description,
                  lastMessageId: newMessage.messageId,
                  lastMessageFrom: newMessage.title,
                  lastMessageFromId: newMessage.userId,
                  userId: newMessage.userId
                });
                chatState.state.userStatus.push({
                  channel: newMessage.channel,
                  lastReadMessage: newMessage.messageId
                });
                this.ns.modifyCounters('add', 'chat', 1);
              }
            }
            return chatState.state.chatList;
          }),
          debug('CHAT LIST'),
        );
      }),
      shareReplay(1)
    );

    this.chats$ = merge(
      chatList$,
      this.cs.myMessage$.pipe(
        withLatestFrom(chatList$),
        debug('Preparing my messages'),
        map(([myMessage, chatList]) => {
            const chat = chatList.find(x => x.channel === myMessage.channel);
            if (chat) {
              chat.lastMessage = myMessage.message;
              chat.lastMessageFrom = 'Me';
            }
            return chatList;
          }
        )
      ));

    this.sub.list = this.g.userStatus$.pipe(tap((data) => this.statuses = data)).subscribe();
  }

  selectChat(state: ChatInitialState, selectedChannel): Observable<ChatState> {
    const chat = state.chatList.find(x => x.channel === selectedChannel);
    if (chat) {
      chat.read = true;
      this.selectedChannel = chat.channel;
      return this.setUserReadChat(state, chat).pipe(
        debug('Setting User Read Chat'),
        concatMap((status) => this.cs.getCounters()),
        debug('Updating counters'),
        tap((counters) => {
          this.ns.notificationCounters.next(counters);
        }),
        map(() => ({state, selectedChannel}))
      );
    }

  }

  ngOnDestroy(): void {
    this.g.showChatNotifications = true;
    this.sub.unsubscribeAll();
  }

  private setUserReadChat(state: ChatInitialState, chat: Chat): Observable<UserChatStatus[]> {
    state.userStatus.find(x => x.channel === chat.channel).lastReadMessage = chat.lastMessageId;
    return this.cs.setUserStatus(state.userStatus);
  }

}
