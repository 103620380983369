import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FriendNotification} from '../../shared/classes/system/FriendNotification';
import {PeopleService} from '../../people/people.service';
import {NotificationAspectBase} from '../_classes/NotificationAspectBase';
import {Subject, Subscription} from 'rxjs';
import {exhaustMap, tap} from 'rxjs/operators';

@Component({
  selector: 'tr-friend-request-base-element',
  templateUrl: './friend-request-base-element.component.html',
  styleUrls: ['./friend-request-base-element.component.scss']
})
export class FriendRequestBaseElementComponent extends NotificationAspectBase implements OnInit, OnDestroy {

  @Input() data: FriendNotification;
  @Output() actionClicked = new EventEmitter<boolean>();

  private accept$ = new Subject<boolean>();

  private sub: Subscription;

  showButtons = true;

  constructor(private ps: PeopleService) {
    super();
  }

  ngOnInit(): void {
    this.sub = this.accept$.pipe(
      exhaustMap((value) => {
        if(value === true) {
          return this.ps.acceptContactRequest(this.data.userId);
        } else {
          return this.ps.denyContactRequest(this.data.userId);
        }
      }),
      tap(() => {
        this.dismiss();
      })
    ).subscribe();
  }

  dismiss() {
    this.showButtons = false;
    this.actionClicked.emit(true);
  }

  accept() {
    this.accept$.next(true);
  }

  decline() {
    this.accept$.next(false);
  }

  ngOnDestroy(): void {
    if(this.sub) {
      this.sub.unsubscribe();
    }
  }

}
