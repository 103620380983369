import {Component, Input, OnInit, Output} from '@angular/core';
import {ImageGalleryItem} from '../../classes/models/ImageGalleryItem';
import {BehaviorSubject, Subject} from 'rxjs';
import {SubscriptionHandler} from '../../classes/system/SubscriptionHandler';
import {tap} from 'rxjs/operators';
import {debug} from '../../classes/system/ObservableDebug';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'tr-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0, transform: 'scale(0.5)' }), // start state
        animate(
          '200ms cubic-bezier(0.785, 0.135, 0.15, 0.86)',
          style({ opacity: 1, transform: 'scale(1)' })
        )
      ]),
      transition('* => void', [
        animate(
          '200ms cubic-bezier(0.785, 0.135, 0.15, 0.86)',
          style({ opacity: 0, transform: 'scale(0.5)' })
        )
      ])
    ])
  ]
})
export class ViewerComponent implements OnInit {

  @Input() images: ImageGalleryItem[];

  @Input() set selectedIndex(value) {
    if (this.selectedIndex$.value !== value) {
      this.selectedIndex$.next(value);
    }
  }

  next$ = new Subject<MouseEvent>();
  previous$ = new Subject<MouseEvent>();
  @Output() closeClicked = new Subject<MouseEvent>();

  selectedIndex$ = new BehaviorSubject<number>(0);

  private sub = new SubscriptionHandler();

  constructor() {
    this.sub.list = this.next$.pipe(
      tap((event) => event.stopPropagation()),
      tap(() => this.selectedIndex$.next((this.selectedIndex$.value + 1) % this.images.length))
    ).subscribe();

    this.sub.list = this.previous$.pipe(
      tap((event) => event.stopPropagation()),
      tap(() => this.selectedIndex$.next(
        ((((this.selectedIndex$.value - 1) % this.images.length) + this.images.length) % this.images.length))
      )
    ).subscribe();

    this.sub.list = this.selectedIndex$.pipe(
      debug('Selected Index')
    ).subscribe();


  }

  ngOnInit(): void {
  }

  imageTrackBy(index: number, item: ImageGalleryItem) {
    return item.id;
  }

}
