import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Media} from '../classes/models/Media';
import {Subject} from 'rxjs';
import {SubscriptionHandler} from '../classes/system/SubscriptionHandler';
import {exhaustMap} from 'rxjs/operators';
import {StatsService} from '../stats.service';
import {debug} from '../classes/system/ObservableDebug';
import {GlobalsService} from '../globals.service';

@Component({
  selector: 'tr-media-item',
  templateUrl: './media-item.component.html',
  styleUrls: ['./media-item.component.scss']
})
export class MediaItemComponent implements OnInit {

  @Input() item: Media;
  @Input() mode = 'h';
  @Input() showBackground = true;

  constructor(public g: GlobalsService) {

  }

  ngOnInit(): void {
  }


}
