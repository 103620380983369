import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {concatMap, delay, take, tap} from 'rxjs/operators';
import {debug} from '../classes/system/ObservableDebug';
import {CallService} from '../call.service';
import {Router} from '@angular/router';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {IncomingCallBase} from '../classes/system/IncomingCallBase';
import {GlobalsService} from '../globals.service';

@Component({
  selector: 'tr-calling',
  templateUrl: './calling.component.html',
  styleUrls: ['./calling.component.scss']
})
export class CallingComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  public callTimeout = false;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: IncomingCallBase,
              private call: CallService,
              private bottomSheetRef: MatBottomSheetRef<CallingComponent>,
              private g: GlobalsService) {
  }

  ngOnInit(): void {

    this.subs.push(
      this.call.callAccepted$.pipe(
        debug('Call Accepted'),
        tap(() => {
          this.bottomSheetRef.dismiss();
          this.g.openBreakoutRoom(this.data.commonToken);
        })
      ).subscribe()
    );

    this.subs.push(
      this.call.callRejected$.pipe(
        debug('Call Rejected'),
        tap(() => {
          this.bottomSheetRef.dismiss();
        })
      ).subscribe()
    );

    this.subs.push(timer(20000).pipe(
      take(1),
      debug('Call Timeout'),
      tap(() => this.callTimeout = true),
      concatMap(() => this.call.reportLostCall(this.data.userId, 'lost')),
      delay(1000),
      tap(() => {
        this.bottomSheetRef.dismiss();
      })
    ).subscribe());
  }

  ngOnDestroy(): void {
    for (const s of this.subs) {
      s.unsubscribe();
    }
  }

  cancelOrClose() {
    // if (!this.callTimeout) {
    //   this.call.reportLostCall(this.data.userId, 'canceled').subscribe();
    // }
    this.bottomSheetRef.dismiss('closed');
  }

}
