import {EventEmitter, Inject, Injectable, NgZone, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {BehaviorSubject, from, Observable, of, Subject, timer} from 'rxjs';
import {catchError, concatMap, exhaustMap, filter, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {EventData} from './classes/models/EventData';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import * as moment from 'moment-timezone';
import {Moment} from 'moment';
import {debug} from './classes/system/ObservableDebug';
import {v4 as uuidv4} from 'uuid';
import {ErrorDialogComponent} from './error-dialog/error-dialog.component';
import {InformationDialogComponent} from './information-dialog/information-dialog.component';
import * as introJs from 'intro.js/intro.js';
import {HitLogData} from './classes/system/HitLogData';
import {StatsService} from './stats.service';
import {TranslateService} from '@ngx-translate/core';
import {ClientConfigService} from './client-config.service';
import {SwUpdate} from '@angular/service-worker';
import {marker as trans} from '@biesbjerg/ngx-translate-extract-marker';
import {Socket} from 'socket.io-client';
import {AppConfig} from './classes/config/AppConfig';
import {LiveChatWidgetApiModel} from '@lsparagino/angular-widget/dist';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  public isBrowser: boolean;
  public isLoading: boolean;
  public isLoggedIn: boolean;

  public showChatNotifications: boolean;

  public e: EventData;

  public socket: Socket;

  public reactions: EventEmitter<string>;

  public userStatus$: Observable<number[]>;
  public routeEvents$ = new BehaviorSubject<RouterEvent>(null);
  public navigationEnds$ = new BehaviorSubject<RouterEvent>(null);

  public ticker$: Observable<Moment>;
  public minutesInterval$ = new Subject<Moment>();
  public hoursInterval$ = new Subject<Moment>();
  public connectionIssues$ = new BehaviorSubject<boolean>(false);
  public showConnectionIssues = false;
  public showChatBox$ = new Subject<boolean>();
  public hideSupportChat$ = new BehaviorSubject<boolean>(false);
  public supportChatLoaded$ = new BehaviorSubject<boolean>(false);
  public isHomePage$ = new BehaviorSubject<boolean>(true);
  public supportChat: LiveChatWidgetApiModel;
  public logout$ = new Subject<boolean>();

  public goBackUrl: string;

  public showSponsorLogo = false;

  public reactionDict = new Map<string, string>(
    [
      ['👍', 'thumb_up'],
      ['👏', 'clap'],
      ['💪', 'flex'],
      ['❤️', 'heart'],
      ['😍️', 'heart_eyes'],
      ['😁', 'smile']
    ]
  );

  public introJS = introJs();

  public c: AppConfig;

  needsReload = false;

  public isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
    .pipe(
      map(result => result.matches),
      shareReplay(1)
    );

  public isPortrait$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.HandsetPortrait)
    .pipe(
      map(result => result.matches),
      shareReplay(1)
    );

  public log$ = new Subject<HitLogData>();

  constructor(@Inject(PLATFORM_ID) private platformId,
              private breakpointObserver: BreakpointObserver,
              private router: Router,
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              private bottomSheet: MatBottomSheet,
              private http: HttpClient,
              private ss: StatsService,
              private ts: TranslateService,
              private cc: ClientConfigService,
              private update: SwUpdate,
              private zone: NgZone
  ) {

    this.c = this.cc.getConfig();

    this.update.available.pipe(
      debug('Update Available'),
      concatMap(() => from(update.activateUpdate())),
      tap(() => document.location.reload())
    ).subscribe();

    this.update.activated.pipe(
      debug('Update Activated')
    ).subscribe();

    this.router.events.pipe(
      tap((event: RouterEvent) => this.routeEvents$.next(event))
    ).subscribe();

    this.routeEvents$.pipe(
      filter(event => event instanceof NavigationEnd),
      tap((event: NavigationEnd) => this.navigationEnds$.next(event)),
      switchMap(() => this.checkForUpdates()),
    ).subscribe();

    this.isBrowser = isPlatformBrowser(platformId);
    this.isLoading = true;
    this.isLoggedIn = false;
    this.reactions = new EventEmitter<string>();
    this.showChatNotifications = true;

    this.log$.pipe(
      debug('HitLog'),
      exhaustMap((log) => this.ss.logActivity(log.entity, log.id, log.data))
    ).subscribe();

    this.hideSupportChat$.pipe(
      tap((hide) => {
        const chat = document.getElementById('chat-widget-container');
        if (chat) {
          if (hide) {
            chat.classList.add('d-none');
          } else {
            chat.classList.remove('d-none');
          }
        }
      })
    ).subscribe();

    this.ticker$ = timer(0, 1000).pipe(
      map(() =>
        // moment('2020-10-07T16:16:00+02:00')
        moment().tz('Europe/Rome')
      ),
      tap((time) => {
        if (time.seconds() === 0) {
          this.minutesInterval$.next(time);
        }
        if (time.minutes() === 0) {
          this.hoursInterval$.next(time);
        }
      }),
      shareReplay(1)
    );

    this.introJS.setOptions(
      {
        nextLabel: 'AVANTI',
        prevLabel: 'INDIETRO',
        skipLabel: 'TERMINA',
        doneLabel: 'FATTO',
        hidePrev: true,
        hideNext: true,
        tooltipClass: '',
        highlightClass: '',
        exitOnEsc: true,
        exitOnOverlayClick: true,
        showStepNumbers: false,
        keyboardNavigation: true,
        steps: [
          {
            element: '#step1',
            intro: 'Questo è l\'ingresso con i link',
            position: 'right'
          },
          {
            element: '#step2',
            intro: 'Questa è la lista degli eventi live',
            position: 'left'
          },
          {
            element: '#main-menu-1',
            intro: 'Menu 1',
            position: 'bottom'
          },
          {
            element: '#main-menu-2',
            intro: 'Menu 2',
            position: 'bottom'
          },
          {
            element: '#main-menu-3',
            intro: 'Menu 3',
            position: 'bottom'
          },
          {
            element: '#main-menu-4',
            intro: 'Menu 4',
            position: 'bottom'
          },
          {
            element: '#notifications-bell',
            intro: 'Area notifiche',
            position: 'bottom'
          },
          {
            element: '#chat-button',
            intro: 'Area chat',
            position: 'bottom'
          }
        ],
        showProgress: false
      }
    );
  }

  public handleSingleSession(sid) {
    if (this.c.switches.forceSingleSession && sid !== this.c.sid) {
      this.dialog.open(ErrorDialogComponent, {
        maxWidth: '900px',
        data: {
          title: this.ts.instant(trans('common.singleSessionTitle')),
          message: this.ts.instant(trans('common.singleSessionMessage'))
        }
      });
      this.logout$.next(true);
    }
  }

  public loading() {
    this.isLoading = true;
  }

  public loaded() {
    this.isLoading = false;
  }

  postLogin() {
    if (this.c.switches.forceSingleSession) {
      this.c.sid = uuidv4();
      return this.http.post<any>(`${environment.BASE_URL}/user-profile/login`, {sid: this.c.sid});
    }
    return of(null);
  }

  public sendReaction(reaction) {
    this.socket.emit('reaction', reaction);
  }

  public isNotIE() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const appName = window.navigator.appName;

    return !(appName === 'Microsoft Internet Explorer' || // IE <= 10
      (appName === 'Netscape' && userAgent.indexOf('trident') > -1)); // IE >= 11
  }

  public isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  public openBreakoutRoom(roomId) {
    this.zone.run(() => {
      this.goBackUrl = this.router.url;
      if (this.isNotIE()) {
        // TODO: temporary fix
        window.open(`${environment.clientUrl}/meet/${roomId}`, '_self');
        // this.router.navigate(['meet', roomId]);
      } else {
        this.router.navigate(['unsupported']);
      }
    });
  }

  public confirm(title, message): Observable<boolean> {
    return this.dialog.open(ConfirmDialogComponent, {
      minWidth: '400px',
      maxWidth: '70vw',
      data: {title, message}
    }).afterClosed();
  }

  public information(title, message, action = 'OK'): Observable<boolean> {
    return this.dialog.open(InformationDialogComponent, {
      minWidth: '400px',
      maxWidth: '70vw',
      data: {title, message, action}
    }).afterClosed();
  }

  public setupStatusHandler() {
    if (!this.userStatus$ && this.c.switches.fetchUserStatus) {
      this.userStatus$ = timer(0, 10000).pipe(
        switchMap(() => {
          return this.http.get<number[]>(`${environment.BASE_URL}/people/status`).pipe(
            catchError((err) => of([]))
          );
        })
      );
    } else {
      this.userStatus$ = of([]);
    }
  }

  public getStatusClass(status) {
    switch (status) {
      case -1:
        return '';
      case 0:
        return 'offline';
      case 1:
        return 'online';
      case 2:
        return 'busy';
      case 3:
        return 'unavailable';
      default:
        return 'offline';
    }
  }

  public now(): Observable<Moment> {
    return this.ticker$;
    // return of(moment('2020-10-05T16:16:00+02:00'));
  }

  setStatus(status) {
    return this.http.post(`${environment.BASE_URL}/people/status`, {status});
  }

  getPersonRouteByType(type) {
    if (type === 'speaker') {
      return 'speakers';
    }

    if (type === 'attendee') {
      return 'lounge';
    }

    return '';
  }

  public checkForUpdates() {
    return from(this.update.checkForUpdate()).pipe(
      debug('Check for Updates'),
      catchError((err) => of(null))
    );
  }
}

export function umans() {
  return JSON.parse(sessionStorage.getItem('app-config'));
}
