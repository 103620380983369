<div class="w-100 h-100 d-flex flex-column">
  <tr-user-notification-base [data]="data" [mode]="mode" [isNew]="isNew"></tr-user-notification-base>
  <button *ngIf="!isExternalUrl"
          mat-button
          [routerLink]="data.action"
          [queryParams]="data.queryParams"
          class="ml-auto white-button"
          (click)="actionClicked.emit(true)">
    {{data.actionLabel}}
  </button>
  <a *ngIf="isExternalUrl"
     mat-button
     target="_blank"
     rel="norel noopener"
     [href]="data.action"
     class="ml-auto white-button"
     (click)="actionClicked.emit(true)">
    {{data.actionLabel}}
  </a>
</div>
