<div class="d-flex h-100 w-100 align-items-center notification mw-100">
  <div class="mr-3">
    <mat-icon [ngClass]="'icon-' + mode" *ngIf="data.type === 'icon'" class="notification-icon">{{data.image}}</mat-icon>
    <tr-profile-image *ngIf="data.type === 'image'" [image]="data.image" [imageSize]="'s'" class="avatar-image"></tr-profile-image>
  </div>
  <div class="d-flex flex-column mw-100" [ngClass]="'text-' + mode">
    <p class="m-0 p-0"><strong>{{data.title}}</strong></p>
    <p class="m-0 p-0 mw-100 notification-text" [class.text-bold]="isNew">{{data.description}}</p>
  </div>
</div>
