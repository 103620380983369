import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ContainerComponent} from './container/container.component';
import {ChatPanelComponent} from './chat-panel/chat-panel.component';


const routes: Routes = [
  {
    path: '',
    component: ContainerComponent,
    children: [
      {path: ':channel', component: ChatPanelComponent}
    ]
  },
  // {
  //   path: 'view',
  //   outlet: 'chat',
  //   component: ContainerComponent,
  //   children: [
  //     {path: ':channel', component: ChatPanelComponent, outlet: 'chat'}
  //   ]
  // }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MessengerRoutingModule {
}
