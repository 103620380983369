export enum PushType {
  FriendRequest = 'friend-request',
  FriendAccepted = 'friend-accepted',
  IncomingCall = 'incoming-call',
  CallAnswered = 'call-answered',
  CallRejected = 'call-rejected',
  CallLost = 'call-lost',
  Sponsored = 'sponsored',
  Trophy = 'trophy'
}
