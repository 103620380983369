import {Component, OnDestroy, OnInit} from '@angular/core';
import {tap} from 'rxjs/operators';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'tr-chat-panel',
  templateUrl: './chat-panel.component.html',
  styleUrls: ['./chat-panel.component.scss']
})
export class ChatPanelComponent implements OnInit, OnDestroy {

  selectedChannel: string;
  private sub: Subscription;

  constructor(private route: ActivatedRoute) {
    this.sub = this.route.paramMap.pipe(
      tap(
        (params: ParamMap) => {
          this.selectedChannel = params.get('channel');
        }
      )
    ).subscribe();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

}
