<div class="message mat-body"
     [ngClass]="chat.isMe ? 'sent' : 'received'"
     [class.staff]="chat.type === 'staff'"
     (mouseenter)="toggleMenuButton(true)"
     (mouseleave)="toggleMenuButton(false)">
  <ng-container *ngIf="!chat.isMe">
    <div *ngIf="chat.type === 'staff'" class="staff-label text-center"><u>STAFF</u></div>
    <strong >{{chat.displayName}}</strong><br>
  </ng-container>
  {{chat.message}}

  <span class="metadata">
    <span class="time mat-caption">{{chat.createdAt | localizedDate: 'shortTime'}}</span>
  </span>

  <button mat-icon-button *ngIf="showMenuButton && (chat.isMe || auth.currentUserValue.role === 'admin' || auth.currentUserValue.role === 'staff')"
          class="menu-button"
          [matMenuTriggerFor]="messageMenu"
          (menuOpened)="menuOpened()"
          (menuClosed)="menuClosed()">
    <mat-icon>expand_more</mat-icon>
  </button>

  <mat-menu #messageMenu="matMenu">
    <button mat-menu-item (click)="deleteChat$.next(true)">
      <mat-icon>delete</mat-icon>
      <span>{{'chat.chat.delete' | translate}}</span>
    </button>
  </mat-menu>
</div>
