import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {PeopleBaseItem} from '../shared/classes/models/PeopleBaseItem';
import {ExhibitorExtendedItem} from '../shared/classes/models/ExhibitorExtendedItem';
import {PeopleExtendedItem} from '../shared/classes/models/PeopleExtendedItem';
import {PeopleTableItem} from '../admin/_classes/PeopleTableItem';

@Injectable({
  providedIn: 'root'
})
export class PeopleService {

  private baseRoute = 'people';

  constructor(private http: HttpClient) {
  }

  getPeople(type): Observable<PeopleBaseItem[]> {
    switch (type) {
      case 'speakers':
        return this.getSpeakers();
      case 'lounge':
        return this.getAttendees();
      default:
        return of([]);
    }
  }


  getPeopleDetail(type, id): Observable<PeopleExtendedItem> {
    switch (type) {
      case 'speakers':
        return this.getSpeaker(id);
      case 'lounge':
        return this.getAttendee(id);
      default:
        return of(null);
    }
  }

  getPeopleTable() {
    return this.http.get<PeopleTableItem[]>(`${environment.BASE_URL}/admin/dashboard/people`);
  }

  getSpeakers(): Observable<PeopleBaseItem[]> {
    return this.http.get<PeopleBaseItem[]>(`${environment.BASE_URL}/${this.baseRoute}/speakers`);
  }

  getAttendees(): Observable<PeopleBaseItem[]> {
    return this.http.get<PeopleBaseItem[]>(`${environment.BASE_URL}/${this.baseRoute}/attendees`);
  }


  getSpeaker(id): Observable<PeopleExtendedItem> {
    return this.http.get<PeopleExtendedItem>(`${environment.BASE_URL}/${this.baseRoute}/speaker/${id}`);
  }

  getAttendee(id): Observable<PeopleExtendedItem> {
    return this.http.get<PeopleExtendedItem>(`${environment.BASE_URL}/${this.baseRoute}/attendee/${id}`);
  }

  sendContactRequest(id): Observable<any> {
    return this.http.post<any>(`${environment.BASE_URL}/${this.baseRoute}/friend-request/${id}`, {});
  }

  acceptContactRequest(id): Observable<any> {
    return this.http.post<any>(`${environment.BASE_URL}/${this.baseRoute}/friend-request/${id}/accept`, {});
  }

  denyContactRequest(id): Observable<any> {
    return this.http.post<any>(`${environment.BASE_URL}/${this.baseRoute}/friend-request/${id}/deny`, {});
  }


}
