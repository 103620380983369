<div class="container text-white noselect">
  <div class="row">
    <ng-container *ngIf="showQrCode; else showWarning">
      <div class="col">
        <p>Visit the url: <strong>{{clientUrl}}</strong> on your smartphone, or scan this QR Code</p>
        <div class="d-flex justify-content-center">
          <qrcode class="my-2"
                  [qrdata]="clientUrl"
                  [width]="256"
                  [errorCorrectionLevel]="'M'">
          </qrcode>
        </div>
      </div>
    </ng-container>
    <ng-template #showWarning>
      <div class="col-sm-auto col-12">
        <div class="d-flex w-100 justify-content-center flex-sm-column flex-row">
          <mat-icon class="mx-1 large-icon">warning</mat-icon>
          <mat-icon class="mx-1 large-icon">wifi_off</mat-icon>
        </div>
      </div>
      <div class="col">
        <h2 class="text-center text-sm-left">WARNING</h2>
        <p>There seem to be some issues with your connection.<br>Please make sure that:</p>
        <ul>
          <li>You are connected to the internet</li>
          <li>You are using an updated browser. Google Chrome or the latest Microsoft Edge are recommended.</li>
          <li>You are not using a VPN or a firewall that limits your connection</li>
        </ul>
        <p>If you still have troubles, try one of the options below</p>
      </div>
    </ng-template>
  </div>
  <div class="row mt-3">
    <div class="col-6 col-sm-4" *ngFor="let a of actions" [ngClass]="a.name === 'mobile' ? 'd-none d-sm-block' : ''">
      <div class="d-flex flex-column align-items-center justify-content-center mx-2">
        <button mat-fab (click)="a.action()"
                [ngClass]="a.name === 'mobile' && showQrCode ? '' : 'white-button'">
          <mat-icon>{{a.icon}}</mat-icon>
        </button>
        <span class="mt-1 text-center">{{a.label}}</span>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <div class="d-flex w-100 align-items-center justify-content-center">
        <button mat-raised-button color="warn" (click)="g.connectionIssues$.next(false)">CLOSE</button>
      </div>
    </div>
  </div>

</div>
