import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {FriendNotification} from '../../shared/classes/system/FriendNotification';

@Component({
  selector: 'tr-friend-request-snack',
  templateUrl: './friend-request-snack.component.html',
  styleUrls: ['./friend-request-snack.component.scss']
})
export class FriendRequestSnackComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: FriendNotification,
              private snackBarRef: MatSnackBarRef<FriendRequestSnackComponent>
  ) {
  }

  dismiss() {
    this.snackBarRef.dismiss();
  }
}
