import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {GlobalsService} from './globals.service';
import {AuthenticationService} from './authentication.service';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {ErrorPageComponent} from './error-page/error-page.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {LanguageSwitcherComponent} from './language-switcher/language-switcher.component';
import {UnsupportedComponent} from './unsupported/unsupported.component';
import {LocalizedDatePipe} from './classes/system/LocalizedDatePipe';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FilterBarComponent} from './filter-bar/filter-bar.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {IncomingCallComponent} from './incoming-call/incoming-call.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RouterModule} from '@angular/router';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {ProfileImageComponent} from './profile-image/profile-image.component';
import {AgendaComponent} from './agenda/agenda.component';
import {AgendaItemComponent} from './agenda-item/agenda-item.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDividerModule} from '@angular/material/divider';
import {MediaItemComponent} from './media-item/media-item.component';
import {NgxFilesizeModule} from 'ngx-filesize';
import {MatExpansionModule} from '@angular/material/expansion';
import {ConnectionIssuesComponent} from './connection-issues/connection-issues.component';
import {CallingComponent} from './calling/calling.component';
import {CALLING_TOKEN, INCOMING_CALL_TOKEN} from './classes/system/DialogTokens';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ErrorDialogComponent} from './error-dialog/error-dialog.component';
import {SafeUrlPipe} from './classes/system/SafeUrlPipe';
import { InformationDialogComponent } from './information-dialog/information-dialog.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import {NgxMasonryModule} from 'ngx-masonry';
import { ViewerComponent } from './image-gallery/viewer/viewer.component';
import {TimedContentComponent} from './timed-content/timed-content.component';
import {ImgErrorDirective} from './classes/system/ImgErrorDirective';
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {QRCodeModule} from 'angularx-qrcode/dist/angularx-qrcode';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    PageNotFoundComponent,
    ErrorPageComponent,
    LanguageSwitcherComponent,
    UnsupportedComponent,
    LocalizedDatePipe,
    SafeUrlPipe,
    ConfirmDialogComponent,
    FilterBarComponent,
    IncomingCallComponent,
    ProfileImageComponent,
    AgendaComponent,
    AgendaItemComponent,
    MediaItemComponent,
    ConnectionIssuesComponent,
    CallingComponent,
    ErrorDialogComponent,
    InformationDialogComponent,
    ImageGalleryComponent,
    ViewerComponent,
    TimedContentComponent,
    ImgErrorDirective
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    FormsModule,
    MatInputModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    NgxFilesizeModule,
    MatExpansionModule,
    QRCodeModule,
    VgCoreModule,
    MatTooltipModule,
    NgxMasonryModule
  ],
  providers: [
    MatSnackBar,
    MatBottomSheet,
    {provide: INCOMING_CALL_TOKEN, useValue: IncomingCallComponent},
    {provide: CALLING_TOKEN, useValue: CallingComponent},
  ],
  exports: [TranslateModule, LanguageSwitcherComponent, LocalizedDatePipe, FilterBarComponent, ProfileImageComponent,
    AgendaComponent, MediaItemComponent, ConnectionIssuesComponent, SafeUrlPipe, ImageGalleryComponent, ImgErrorDirective]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        GlobalsService,
        AuthenticationService
      ]
    };
  }
}
