import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FriendRequestSnackComponent} from './friend-request-snack/friend-request-snack.component';
import {NotificationSnackComponent} from './notification-snack/notification-snack.component';
import {UserNotificationBaseComponent} from './user-notification-base/user-notification-base.component';
import {NotificationSnackBaseComponent} from './notification-snack-base/notification-snack-base.component';
import {MatButtonModule} from '@angular/material/button';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {NotificationBaseElementComponent} from './notification-base-element/notification-base-element.component';
import {FriendRequestBaseElementComponent} from './friend-request-base-element/friend-request-base-element.component';
import {SharedModule} from '../shared/shared.module';
import {CallLostComponent} from './call-lost/call-lost.component';
import { NotificationTrophyBaseComponent } from './notification-trophy-base/notification-trophy-base.component';
import { NotificationTrophySnackComponent } from './notification-trophy-snack/notification-trophy-snack.component';


@NgModule({
  declarations: [
    FriendRequestSnackComponent,
    NotificationSnackComponent,
    UserNotificationBaseComponent,
    NotificationSnackBaseComponent,
    NotificationBaseElementComponent,
    FriendRequestBaseElementComponent,
    CallLostComponent,
    NotificationTrophyBaseComponent,
    NotificationTrophySnackComponent
  ],
  exports: [
    FriendRequestSnackComponent,
    NotificationSnackComponent,
    UserNotificationBaseComponent,
    NotificationSnackBaseComponent,
    NotificationBaseElementComponent,
    FriendRequestBaseElementComponent,
    CallLostComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    RouterModule,
    MatIconModule,
    SharedModule
  ]
})
export class NotificationItemsModule {
}
