<h1 mat-dialog-title>{{data.title | translate}}</h1>
<div mat-dialog-content>
  <p>
    {{data.message | translate}}
  </p>

</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">{{'common.cancel' | translate}}</button>
  <button mat-raised-button [mat-dialog-close]="true" color="primary">{{'common.ok' | translate}}</button>
</div>
