<!--<div class="w-100 h-100 overflow-hidden d-flex chat-container"-->
<!--     *ngIf="(chats$ | async) as chats; else loading">-->
<!--  <div class="chat-list">-->
<!--    <mat-list class="p-0">-->
<!--      <tr-chat-preview *ngFor="let chat of chats"-->
<!--                       (click)="show(chat)"-->
<!--                       [item]="chat"-->
<!--                       [selected]="selectedChannel === chat.channel"-->
<!--                       [status]="statuses && statuses[chat.userId]">-->
<!--      </tr-chat-preview>-->
<!--    </mat-list>-->
<!--  </div>-->
<!--  <div class="w-100 h-100">-->
<!--    <router-outlet></router-outlet>-->
<!--  </div>-->
<!--</div>-->

<mat-sidenav-container class="sidenav-container" *ngIf="(chats$ | async) as chats; else loading">
    <mat-sidenav #drawer [ngClass]="(mobileVersion$ | async) ? 'sidenav-mobile' : 'sidenav-desktop'" class="chatSidenav"
                 role="dialog"
                 mode="side"
                 opened="true">
      <div class="chat-list">
        <mat-list class="p-0">
          <tr-chat-preview *ngFor="let chat of chats"
                           (click)="openChat$.next(chat)"
                           [item]="chat"
                           [selected]="selectedChannel === chat.channel"
                           [status]="statuses && statuses[chat.userId]">
          </tr-chat-preview>
        </mat-list>
      </div>
    </mat-sidenav>
    <mat-sidenav-content class="h-100 mh-100">
      <div class="d-flex flex-column mh-100 h-100">
        <mat-toolbar class="d-flex align-items-center justify-content-between p-0 pr-3 mat-elevation-z2"
                     *ngIf="(mobileVersion$ | async)">
          <div class="h-100 d-flex align-items-center">
            <button mat-icon-button [attr.aria-label]="'messenger.container.close' | translate" class="mx-2" (click)="drawer.open()">
              <mat-icon>arrow_back</mat-icon>
            </button>
            <mat-divider vertical class="h-100"></mat-divider>
          </div>
          <div class="d-flex align-items-center" *ngIf="(openChat$ | async) as openChat">
            <span class="mat-subheading-1 m-0 p-0">{{openChat.title}}</span>
            <div class="chat-avatar-container mx-2">
              <tr-profile-image [image]="openChat.imageUrl"
                                [status]="g.getStatusClass(statuses && statuses[openChat.userId])"
                                [imageSize]="'s'">
              </tr-profile-image>
            </div>
          </div>
        </mat-toolbar>
        <div class="d-flex h-100 mh-100 w-100 overflow-hidden">
          <div class="text-center p-3 mb-auto item-container" *ngIf="chats.length === 0">
            <span class="pt-2"><strong>{{'messenger.container.noChats' | translate}}</strong></span>
          </div>
          <router-outlet *ngIf="!floating"></router-outlet>
          <tr-chat *ngIf="floating" [channelName]="channelSelected$ | async" class="w-100 h-100"></tr-chat>
        </div>
      </div>

    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #loading>
  <div class="w-100 h-100 d-flex align-items-center justify-content-center">
    <mat-spinner mode="indeterminate" color="primary"></mat-spinner>
  </div>
</ng-template>
