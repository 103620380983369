import {Injectable} from '@angular/core';
import {GlobalsService} from './globals.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ChatNotification} from './classes/system/ChatNotification';
import {NotificationCounters} from './classes/models/NotificationCounters';
import {HttpClient} from '@angular/common/http';
import {NotificationItem} from '../notification/_classes/NotificationItem';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {CallService} from './call.service';
import {NotificationBase} from './classes/system/NotificationBase';
import {NotificationSnackComponent} from '../notification-items/notification-snack/notification-snack.component';
import {FriendNotification} from './classes/system/FriendNotification';
import {FriendRequestSnackComponent} from '../notification-items/friend-request-snack/friend-request-snack.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PushType} from './classes/enums/PushType';
import {TrophyNotification} from './classes/system/TrophyNotification';
import {NotificationTrophySnackComponent} from '../notification-items/notification-trophy-snack/notification-trophy-snack.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  public notificationsChannel;
  public privateChannel;

  public chatNotifications = new BehaviorSubject<ChatNotification>(null);
  public notificationCounters = new BehaviorSubject<NotificationCounters>(null);

  public friendStatusChange$ = new Subject<boolean>();

  constructor(private g: GlobalsService, private http: HttpClient, private call: CallService, private snackBar: MatSnackBar) {

  }

  setupHandlers(pusher, uid) {
    this.notificationsChannel = pusher.subscribe('private-app');
    this.privateChannel = pusher.subscribe('private-u-' + uid);

    this.privateChannel.bind('login', (data) => {
        this.g.handleSingleSession(data.sid);
    });

    this.privateChannel.bind('chat-ping', (data) => {
      this.chatNotifications.next(data);
      if (this.g.showChatNotifications) {
        this.showNotificationSnackBar(data);
        this.modifyCounters('add', 'chat', 1);
      }
    });

    this.privateChannel.bind(PushType.FriendRequest, (data) => {
      this.showFriendRequestSnackBar(data);
      this.modifyCounters('add', 'other', 1);
      this.friendStatusChange$.next(true);
    });

    this.privateChannel.bind(PushType.FriendAccepted, (data) => {
      this.showNotificationSnackBar(data);
      this.modifyCounters('add', 'other', 1);
      this.friendStatusChange$.next(true);
    });

    this.privateChannel.bind(PushType.Sponsored, (data) => {
      this.showNotificationSnackBar(data);
      this.modifyCounters('add', 'other', 1);
    });

    this.notificationsChannel.bind(PushType.Sponsored, (data) => {
      this.showNotificationSnackBar(data);
      this.modifyCounters('add', 'other', 1);
    });

    this.privateChannel.bind(PushType.Trophy, (data) => {
      this.showTrophyNotificationSnackBar(data);
      // this.modifyCounters('add', 'other', 1);
    });

    this.privateChannel.bind(PushType.IncomingCall, (data) => {
      this.call.incomingCall$.next(data);
    });

    this.privateChannel.bind(PushType.CallAnswered, (data) => {
      this.call.callAccepted$.next(true);
    });

    this.privateChannel.bind(PushType.CallRejected, (data) => {
      this.call.callRejected$.next(true);
    });

    this.privateChannel.bind(PushType.CallLost, (data) => {
      this.call.callLost$.next(data);
    });

    this.privateChannel.bind('event-starting', (data) => {
      this.showNotificationSnackBar(data);
      this.modifyCounters('add', 'other', 1);
    });
  }

  modifyCounters(op: 'add' | 'sub', item: 'chat' | 'other', count: number) {
    const counters = this.notificationCounters.value;

    if(!counters) {
      return;
    }

    if (op === 'sub') {
      count = -count;
    }

    switch (item) {
      case 'chat':
        counters.unreadMessages += count;
        break;
      case 'other':
        counters.unreadNotifications += count;
    }

    this.notificationCounters.next(counters);
  }

  resetPushCounter() {
    const counters = this.notificationCounters.value;
    counters.unreadNotifications = 0;
    this.notificationCounters.next(counters);
  }

  getNotificationsList(): Observable<NotificationItem[]> {
    return this.http.get<NotificationItem[]>(`${environment.BASE_URL}/notifications`).pipe(
      map((data) => {
        const e = data;
        e.forEach((i) => {
          i.created = moment(i.created);
        });
        return e;
      }),
    );
  }

  readAll() {
    return this.http.post<any>(`${environment.BASE_URL}/notifications/read-all`, {});
  }

  public showNotificationSnackBar(notification: NotificationBase) {

    this.snackBar.openFromComponent(NotificationSnackComponent, {
      duration: 8000,
      data: notification,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'snack-primary'
    });

  }

  public showFriendRequestSnackBar(notification: FriendNotification) {

    this.snackBar.openFromComponent(FriendRequestSnackComponent, {
      duration: 8000,
      data: notification,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'snack-primary'
    });

  }

  public showTrophyNotificationSnackBar(notification: TrophyNotification) {

    this.snackBar.openFromComponent(NotificationTrophySnackComponent, {
      duration: 8000,
      data: notification,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'snack-primary'
    });

  }

}
