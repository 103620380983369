import {isDevMode} from '@angular/core';
import {Observable, OperatorFunction} from 'rxjs';
import {environment} from '../../../../environments/environment';

export function debug<T>(message?: string, additionalData?: any): OperatorFunction<T, T> {
  return (source$: Observable<T>): Observable<T> => {
    return new Observable<T>(observer => {
      let id = null;
      if (isDevMode() || environment.enableDebug) {
        id = Math.floor(Date.now() + (Date.now() * Math.random())).toString(36);
      }
      const wrapper = {
        next(value) {
          if (isDevMode() || environment.enableDebug) {
            console.log(`%c[${id} ${message}: Next]`, 'background: #84d3f5; color: #000; padding: 3px; font-size: 9px;',
              value,
              additionalData);
          }
          observer.next(value);
        },
        error(error) {
          if (isDevMode() || environment.enableDebug) {
            console.log(`%c[${id} ${message}: Error]`, 'background: #bf1408; color: #fff; padding: 3px; font-size: 9px;',
              error,
              additionalData);
          }
          observer.error(error);
        },
        complete() {
          if (isDevMode() || environment.enableDebug) {
            console.log(`%c[${id} ${message}]: Complete`, 'background: #277a35; color: #fff; padding: 3px; font-size: 9px;');
          }
          observer.complete();
        }
      };
      return source$.subscribe(wrapper);
    });
  };
}
