import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {CallService} from '../call.service';
import {IncomingCallBase} from '../classes/system/IncomingCallBase';
import {Subject, Subscription} from 'rxjs';
import {debug} from '../classes/system/ObservableDebug';
import {exhaustMap, tap} from 'rxjs/operators';
import {GlobalsService} from '../globals.service';

@Component({
  selector: 'tr-incoming-call',
  templateUrl: './incoming-call.component.html',
  styleUrls: ['./incoming-call.component.scss']
})
export class IncomingCallComponent implements OnInit, OnDestroy {

  public accept$ = new Subject<boolean>();
  public deny$ = new Subject<boolean>();

  private subs: Subscription[] = [];

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: IncomingCallBase,
              private call: CallService,
              private bottomSheetRef: MatBottomSheetRef<IncomingCallComponent>,
              private g: GlobalsService
  ) {
  }

  ngOnInit(): void {

    this.subs.push(this.accept$.pipe(
      debug('Accept Call'),
      exhaustMap(() => this.call.acceptCall(this.data.userId)),
      tap(() => {
        this.bottomSheetRef.dismiss();
        this.g.openBreakoutRoom(this.data.commonToken);
      })
    ).subscribe());

    this.subs.push(this.deny$.pipe(
      debug('Deny Call'),
      exhaustMap(() => this.call.rejectCall(this.data.userId)),
      tap(() => {
        this.bottomSheetRef.dismiss();
      })
    ).subscribe());


  }

  ngOnDestroy(): void {
    for (const s of this.subs) {
      s.unsubscribe();
    }
  }


}
