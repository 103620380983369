import {Subscription} from 'rxjs';

export class SubscriptionHandler {

  private sub: Subscription;

  constructor() {
    this.sub = new Subscription();
  }

  public set list(value: Subscription) {
    this.sub.add(value);
  }

  public get list() {
    return this.sub;
  }

  public unsubscribeAll() {
    this.sub.unsubscribe();
  }

}
