import {Component, Input, OnInit} from '@angular/core';
import {Chat} from '../_classes/Chat';
import {GlobalsService} from '../../shared/globals.service';

@Component({
  selector: 'tr-chat-preview',
  templateUrl: './chat-preview.component.html',
  styleUrls: ['./chat-preview.component.scss']
})
export class ChatPreviewComponent implements OnInit {

  @Input() item: Chat;
  @Input() selected: boolean;
  @Input() status: number;

  constructor(public g: GlobalsService) { }

  ngOnInit(): void {
  }

}
