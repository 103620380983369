import {Component, OnDestroy, OnInit} from '@angular/core';
import {GlobalsService} from './shared/globals.service';
import {TranslateService} from '@ngx-translate/core';
import {catchError, filter, takeUntil, tap} from 'rxjs/operators';
import {NavigationEnd, ResolveEnd, Router, RouterEvent} from '@angular/router';
import {UserStatusEnum} from './shared/classes/enums/UserStatusEnum';
import { Observable, of, Subject, Subscription} from 'rxjs';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {AuthenticationService} from './shared/authentication.service';
import {debug} from './shared/classes/system/ObservableDebug';
import {SubscriptionHandler} from './shared/classes/system/SubscriptionHandler';
@Component({
  selector: 'tr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private sub = new SubscriptionHandler();

  // private onDestroy$ = new Subject<boolean>();
  public connectionIssues$: Observable<boolean>;

  constructor(public g: GlobalsService,
              private translate: TranslateService,
              private router: Router,
              private ga: GoogleAnalyticsService,
              private auth: AuthenticationService
  ) {
    if (!this.g.isNotIE()) {
      this.router.navigate(['/unsupported']);
    }

    translate.addLangs(['en', 'it']);
    translate.setDefaultLang(this.g.c.language.defaultLanguage);

    if (this.g.c.language.useBrowserLanguage) {
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|it/) ? browserLang : this.g.c.language.defaultLanguage);
    } else {
      translate.use(this.g.c.language.defaultLanguage);
    }

    this.connectionIssues$ = this.g.connectionIssues$.pipe(
      debug('Toggle connection issues')
    );
  }

  ngOnInit(): void {
    this.g.loaded();

    this.sub.list = this.g.routeEvents$.pipe(
      // takeUntil(this.onDestroy$),
      tap((event: RouterEvent) => {
        if (event instanceof ResolveEnd) {
          const e = event as ResolveEnd;
          if (this.auth.currentUserValue) {
            this.g.isHomePage$.next(e.url === '/');
            if (e.url.startsWith('/meet') || e.url.startsWith('/keynote')) {
              this.g.showChatBox$.next(false);
              this.g.setStatus(UserStatusEnum.Unavailable).pipe(
                catchError((err) => of(null))
              ).subscribe();
            } else {
              this.g.setStatus(UserStatusEnum.Online).pipe(
                catchError((err) => of(null))
              ).subscribe();
            }
          }

          this.g.showSponsorLogo = e.url.startsWith('/people/lounge');

        } else if (event instanceof NavigationEnd) {
          const e = event as ResolveEnd;
          this.ga.pageView(e.urlAfterRedirects, undefined, undefined, {
            user_id: this.auth.currentUserValue ? this.auth.currentUserValue.id : 0
          });
        }
      })
    ).subscribe();

    this.sub.list = this.g.showChatBox$.pipe(
      tap((value) => {
        // if (value) {
        //   this.router.navigate([{outlets: { chat: 'chat' }}], {skipLocationChange: true});
        //   // this.router.navigate([{outlets: { chat: 'chat' }}]);
        // } else {
        //   this.router.navigate([{outlets: { chat: null }}], {skipLocationChange: true});
        //   // this.router.navigate([{outlets: { chat: null }}]);
        // }
      })
    ).subscribe();

  }

  ngOnDestroy(): void {
    // this.onDestroy$.next(true);
    this.sub.unsubscribeAll();
  }

}
