<div class="w-100 h-100 app-bg">
  <tr-language-switcher class="language" *ngIf="g.c.switches.showLanguageSwitch"></tr-language-switcher>
  <router-outlet></router-outlet>

  <div class="d-flex justify-content-center align-items-center vh-100 vw-100 loader" *ngIf="g.isLoading">
    <mat-progress-spinner
      color="accent"
      mode="indeterminate">
    </mat-progress-spinner>
  </div>

  <div class="d-flex justify-content-center align-items-center vh-100 vw-100 loader overflow-hidden"
       *ngIf="g.showConnectionIssues && ((connectionIssues$ | async) === true)">
    <tr-connection-issues></tr-connection-issues>
  </div>
</div>

<div class="chat-box mat-elevation-z4" *ngIf="g.showChatBox$ | async">
  <div class="d-flex flex-column w-100 h-100">
    <mat-toolbar class="d-flex w-100" color="primary">
      <span>{{'common.chat' | translate}}</span>
      <button mat-icon-button (click)="g.showChatBox$.next(false)" class="ml-auto">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
    <div class="d-flex w-100 h-100 mh-100">
      <div class="position-relative w-100 h-100 mh-100">
        <div class="scroll-abs-cont">
          <tr-messenger-container [floating]="true"></tr-messenger-container>
        </div>
      </div>
    </div>
  </div>
</div>
