import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FilterConfig} from './FilterConfig';
import {IFilterItem} from '../classes/system/IFilterItem';
import {fromEvent, Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import {MatInput} from '@angular/material/input';
import {FormControl} from '@angular/forms';
import {GlobalsService} from '../globals.service';

@Component({
  selector: 'tr-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit, OnDestroy {

  @Input() title: string;
  @Input() filters: FilterConfig[];
  @Input() searchBoxPlaceholder = 'Search';
  @Input() filterButtonId = null;

  @Output() filtersChanged = new EventEmitter<boolean>();
  @Output() searchBoxTyped = new EventEmitter<string>();


  private sub: Subscription;

  searchBox: FormControl;
  panelOpenState = false;

  constructor(public g: GlobalsService) {
    this.searchBox = new FormControl();
  }

  ngOnInit(): void {
    // if (this.filters) {
    //   this.filters.forEach(f => f.filters.forEach(i => i.selected = false));
    // }

    this.sub = this.searchBox.valueChanges.pipe(
      map((text) => text?.length > 0 ? text : null),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.searchBoxTyped.emit(text);
    });


  }

  applyFilter(item: IFilterItem) {
    item.selected = !item.selected;
    this.filtersChanged.emit(true);
  }

  isActive(filterConfig: FilterConfig) {
    return filterConfig.filters.some(x => x.selected === true);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  toggleSearchPanel() {
    this.panelOpenState = !this.panelOpenState;
  }
}
