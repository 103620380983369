<div class="w-100 h-100 d-flex flex-column">
  <div class="d-flex justify-content-between" *ngIf="showOnAir">
    <h4 class="text-m d-flex align-items-center">
      {{'shared.agenda.onAir' | translate}} <span class="liveDot ml-2"></span>
    </h4>
    <span class="text-s">{{(g.now() | async).toDate() | date: 'shortDate'}} </span>
  </div>
  <div class="w-100 agenda-container h-100 overflow-auto" *ngIf="eventData as events; else loader">
    <div class="text-m text-center" *ngIf="events.length === 0">{{'shared.agenda.noLiveEvents' | translate}}</div>
    <tr-agenda-item *ngFor="let i of events" [agendaItem]="i" [mode]="mode" ></tr-agenda-item>
  </div>
</div>
<ng-template #loader>
  <div class="d-flex w-100 h-100 align-items-center justify-content-center">
    <mat-spinner color="accent" mode="indeterminate"></mat-spinner>
  </div>

</ng-template>
