import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ImageGalleryItem} from '../classes/models/ImageGalleryItem';
import {NgxMasonryComponent, NgxMasonryOptions} from 'ngx-masonry';
import {ViewerComponent} from './viewer/viewer.component';
import {take, tap} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'tr-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() images: ImageGalleryItem[];
  @Input() columns = 4;
  @Input() ordered = false;
  @Input() options: NgxMasonryOptions;
  @Input() gutter: number;
  @Input() minColumnWidth = 300;

  @Output() itemsLoaded = new EventEmitter<boolean>();
  @Output() viewerOpen = new BehaviorSubject<boolean>(false);

  @ViewChild('masonry', { read: ElementRef }) masonryElement: ElementRef;
  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;

  private observer: ResizeObserver;

  responsiveColumns$ = new BehaviorSubject(1);

  constructor(private zone: NgZone) {
    this.responsiveColumns$.next(this.columns);
  }

  ngOnInit(): void {
    this.options = {
      ...this.options,
      gutter: this.gutter
    };
  }

  openViewer(viewer: ViewerComponent, index: number) {
    viewer.selectedIndex$.next(index);
    viewer.closeClicked.pipe(
      tap(() => this.viewerOpen.next(false)),
      take(1)
    ).subscribe();
    this.viewerOpen.next(true);
  }

  ngAfterViewInit(): void {
    this.observer = new ResizeObserver(entries => {
      this.zone.run(() => {
        const width = entries[0].contentRect.width;
        if (width <= this.minColumnWidth) {
          this.responsiveColumns$.next(1);
          return;
        }
        const max = (this.minColumnWidth * this.columns) + (this.gutter * this.columns);
        if (width >= max) {
          this.responsiveColumns$.next(this.columns);
          return;
        }
        this.responsiveColumns$.next(Math.floor(width / (this.minColumnWidth + this.gutter)));
      });
    });

    this.observer.observe(this.masonryElement.nativeElement);
  }

  prepend(item: ImageGalleryItem) {
    this.images.unshift({...item, prepend: true});
  }

  reload() {
    this.masonry.reloadItems();
    this.masonry.layout();
  }

  ngOnDestroy(): void {
    this.observer.unobserve(this.masonryElement.nativeElement);
  }

  imageTrackBy(index: number, item: ImageGalleryItem) {
    return item.id;
  }
}
