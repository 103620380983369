import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TimetableAgenda} from '../classes/models/Timetable';
import {GlobalsService} from '../globals.service';

@Component({
  selector: 'tr-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit {

  @Input() eventData: TimetableAgenda[];
  @Input() mode = 0;
  @Input() showOnAir = false;

  constructor(public g: GlobalsService) {
  }

  ngOnInit(): void {

  }



}
