import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: 'img[trImgError]'
})
export class ImgErrorDirective {

  @Output() trOnImgError = new EventEmitter();

  constructor() {}

  @HostListener('error', ['$event'])
  onError(event) {
    this.trOnImgError.emit();
  }

}
