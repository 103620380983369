import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
// import * as Sentry from "@sentry/angular";
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
// import {Integrations} from '@sentry/tracing';
import * as moment_timezone from 'moment-timezone';

(window as any).moment = moment_timezone;
//
// Sentry.init({
//   dsn: "https://e5e07a03638c440da9ffcc4c6c00c5e6@o455572.ingest.sentry.io/5447281",
//   integrations: [
//     new Integrations.BrowserTracing({
//       tracingOrigins: ["localhost", "https://staging.aippionlinecongress2020.com"],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//
//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

if (environment.production) {
  enableProdMode();
}
fetch(environment.BASE_URL + '/app-config')
  .then(res => {
    if (!res.ok) {
      throw Error(res.statusText);
    }
    return res;
  })
  .then(res => res.text())
  .then((resp) => {
    sessionStorage.setItem('app-config', resp);
    platformBrowserDynamic().bootstrapModule(AppModule)
      .catch(err => console.error(err));
  }).catch((err) => {
  loadingError();
});

function loadingError() {

  const errorMessage = {
    it: {
      title: 'SISTEMA TEMPORANEAMENTE NON DISPONIBILE',
      desc: 'Manutenzione in corso'
    },
    en: {
      title: 'SYSTEM TEMPORARILY UNAVAILABLE',
      desc: 'Maintenance in progress'
    }
  };

  let userLang = navigator.language;
  if (!(userLang in errorMessage)) {
    userLang = 'en';
  }

  const spinner = document.getElementById('spinner');
  const title = document.getElementById('message-title');
  const desc = document.getElementById('message-desc');

  spinner.classList.toggle('d-none');
  title.innerHTML = errorMessage[userLang].title;
  title.classList.toggle('d-none');

  desc.innerHTML = errorMessage[userLang].desc;
  desc.classList.toggle('d-none');
}
