import {Component, Input, OnInit} from '@angular/core';
import {NotificationBase} from '../../shared/classes/system/NotificationBase';
import {NotificationAspectBase} from '../_classes/NotificationAspectBase';

@Component({
  selector: 'tr-user-notification-base',
  templateUrl: './user-notification-base.component.html',
  styleUrls: ['./user-notification-base.component.scss']
})
export class UserNotificationBaseComponent extends NotificationAspectBase implements OnInit {

  @Input() data: NotificationBase;

  ngOnInit(): void {
  }

}
